import {Call, CallAPIGateway, APIJSONResponse, VERBS} from "./api-bridge";

/**
 * QDI X-API wrapper object.
 * 
 * @param 1 of
 *  AssociateQDIUserAccount
 */
export class XBody {
    AssociateQDIUserAccount : AssociateQDIUserAccount

    constructor(account:AssociateQDIUserAccount) {
        this.AssociateQDIUserAccount = account;
    }
}

/**
 * User representation object.
 * 
 * @param user_id
 * @param birthdate yyyy-MM-dd format
 * @param date_of_event yyyy-MM-dd format
 * @param family_name
 * @param given_name
 * @param middle_name
 * @param family_name_1
 * @param given_name_1
 * @param family_name_2
 * @param given_name_2
 * @param issuing_authority
 * @param verified_doc List of documents verified to the user.
 */
export class AssociateQDIUserAccount {
    user_id?: string;
    birthdate: string;
    date_of_event?: string;
    family_name: string;
    given_name?: string;
    middle_name?: string;
    family_name_1?: string;
    given_name_1?: string;
    family_name_2?: string;
    given_name_2?: string;
    issuing_authority: string;
    verified_doc: {
        type_code:string;
        identifiers: Array<{
            value: string | number
            type: string
        }>
    };

    constructor(user_id:string, birthdate:string, family_name:string, given_name:string, middle_name:string, issuing_authority:string, verified_doc:{ type_code: string; identifiers: { value: string | number; type: string;}[]; }, date_of_event?:string, family_name_1?:string, given_name_1?:string, family_name_2?:string, given_name_2?:string) {
        this.user_id = user_id;
        this.birthdate = birthdate;
        this.family_name = family_name;
        this.given_name = given_name;
        this.middle_name = middle_name;
        this.issuing_authority = issuing_authority;
        this.verified_doc = verified_doc;
        if (date_of_event) { this.date_of_event = date_of_event; }
        if (family_name_1) { this.family_name_1 = family_name_1; }
        if (given_name_1) { this.given_name_1 = given_name_1; }
        if (family_name_2) { this.family_name_2 = family_name_2; }
        if (given_name_2) { this.given_name_2 = given_name_2; }
    }

    toObject(): AssociateQDIUserAccount {
        return {...this};
    }
}

export class AssociateQDIUserAccountBuilder {
    user_id: string | undefined;
    birthdate: string | undefined;
    date_of_event: string | undefined;
    family_name: string | undefined;
    given_name: string | undefined;
    middle_name: string | undefined;
    family_name_1: string | undefined;
    given_name_1: string | undefined;
    family_name_2: string | undefined;
    given_name_2: string | undefined;
    issuing_authority: string | undefined;
    verified_doc_type_code:string | undefined;
    identifiers: Array<{
        value: string | number
        type: string
    }>;

    constructor() {
        this.identifiers = []; // always init array to empty
        return this;
    }

    withUserId(user_id:string) {
        this.user_id = user_id;
        return this;
    }

    withBirthdate(birthdate:string) {
        this.birthdate = birthdate;
        return this;
    }

    withDateOfEvent(date_of_event:string) {
        this.date_of_event = date_of_event;
        return this;
    }

    withFamilyName(family_name:string) {
        this.family_name = family_name;
        return this;
    }

    withGivenName(given_name:string) {
        this.given_name = given_name;
        return this;
    }

    withMiddleName(middle_name:string) {
        this.middle_name = middle_name;
        return this;
    }

    withFamilyName1(family_name:string) {
        this.family_name_1 = family_name;
        return this;
    }

    withGivenName1(given_name:string) {
        this.given_name_1 = given_name;
        return this;
    }

    withFamilyName2(family_name:string) {
        this.family_name_2 = family_name;
        return this;
    }

    withGivenName2(given_name:string) {
        this.given_name_2 = given_name;
        return this;
    }

    withIssuingAuthority(issuing_authority:string) {
        this.issuing_authority = issuing_authority;
        return this;
    }

    withVerifiedDocTypeCode(verified_doc_type_code:string) {
        this.verified_doc_type_code= verified_doc_type_code;
        return this;
    }

    withVerifiedDocIdentifier(type:string, value:string | number) {
        this.identifiers.push({type:type, value:value});
        return this;
    }

    build() {
        // it is the responsibility of the called API to reject the payload.
        let verified_doc = {
            type_code : this.verified_doc_type_code!,
            identifiers : this.identifiers!
        }
        let ua = new AssociateQDIUserAccount(this.user_id!, this.birthdate!, this.family_name!, this.given_name!, this.middle_name!, this.issuing_authority!, verified_doc, this.date_of_event!, this.family_name_1!, this.given_name_1!, this.family_name_2!, this.given_name_2!);
        return ua;
    }
}

/**
 * The document has already been used to verify a user account. Equivalent to the API call 409 status response.
 */
export class DocumentAlreadyVerifiedError extends Error {
    constructor() {
        super("Document has already been used to verify an account.");
    }
}

/**
 * The document's details are incorrect, ineligable or not found.
 */
export class DocumentVerificationError extends Error {
    failureCode:string;
    failureMessage: string;

    constructor(failureCode:string, failureMessage:string) {
        super("Document failed Verification with code " + failureCode);
        this.failureCode = failureCode;
        this.failureMessage = failureMessage;
    }
}

/**
 * API that verify's the entered details of a driver's licence.
 * 
 * @param licence an XBody object containing the driver's licence details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @throws DocumentAlreadyVerifiedError when http status code is 409
 * @throws DocumentVerificationError when http status code is 404
 * @throws Error("Unhandled response") if not above conditions or success occurring.
 */
export async function verifyDriversLicence(licence:XBody, token: string) {
    const returned = await Call('qdi/users/identity/dl', VERBS.POST, licence, token, [200, 404, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a user's PIC identification.
 * 
 * @param pic an XBody object containing the PIC identification details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @returns 
 */
export async function verifyPIC(pic:XBody, token: string) {
    const returned = await Call('qdi/users/identity/pic', VERBS.POST, pic, token, [200, 404, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a user's marine licence identification.
 * 
 * @param ml an XBody object containing the Marine Licence identification details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @returns 
 */
 export async function verifyMarineLicence(ml:XBody, token: string) {
    const returned = await Call('qdi/users/identity/ml', VERBS.POST, ml, token, [200, 404, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a passport.
 * 
 * @param passport an XBody object containing passport identification details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @returns 
 */
 export async function verifyPassport(passport:XBody, token:string) {
    const returned = await Call('qdi/users/identity/pp', VERBS.POST, passport, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a birth certificate.
 * 
 * @param certificate an XBody object containing birth certificate details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyBirthCertificate(certificate:XBody, token:string) {
    const returned = await Call('qdi/users/identity/bc', 'POST', certificate, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a passport visa.
 * 
 * @param visa an XBody object containing visa identification details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyVisa(visa:XBody, token:string) {
    const returned = await Call('qdi/users/identity/vi', VERBS.POST, visa, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a citizenship certificate.
 * 
 * @param cc an XBody object containing citizenship certificate details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyCitizenshipCertificate(cc:XBody, token:string) {
    const returned = await Call('qdi/users/identity/cc', VERBS.POST, cc, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of an ImmiCard.
 * 
 * @param cc an XBody object containing immicard details in an AssociateQDIUserAccount instance.
 * @param token 
 * @returns 
 */
export async function verifyImmiCard(immicard:XBody, token:string) {
    const returned = await Call('qdi/users/identity/im', VERBS.POST, immicard, token, [200, 400, 409]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a marriage certificate.
 * 
 * @param marriageCertificate 
 * @param token 
 * @returns 
 */
export async function verifyMarriageCertificate(marriageCertificate:XBody, token:string) {
    const returned = await Call('qdi/users/identity/mc', VERBS.POST, marriageCertificate, token, [200, 400]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that verify's the entered details of a name change certificate.
 * 
 * @param nameChangeCertificate 
 * @param token 
 * @returns 
 */
export async function verifyNameChangeCertificate(nameChangeCertificate:XBody, token:string) {
    const returned = await Call('qdi/users/identity/nc', VERBS.POST, nameChangeCertificate, token, [200, 400]);

    return handleDocumentVerificationResponse(returned);
}

/**
 * API that deletes the latest document uploaded to Auth0 of a given user.
 * 
 * @param userId 
 * @param token 
 * @returns 
 */
export async function deleteLatestDocument(userId:string, token:string) {
    userId = encodeURI(userId);
    const response = await Call(`qdi/users/${userId}/identity/latestdocument`, VERBS.DELETE, null, token, [200, 204, 400]);

    if (response.code === 204) {
        return true;
    }
    else {
        throw new Error("Unhandled response.")
    }
}

/**
 * Get the QDI Service Status from QDI-Service-Status API.
 * @returns the server response if the response is a 200 response.
 */
export async function getQDIServiceStatus() {
    const response = await CallAPIGateway(`service-status`, VERBS.GET, null, "");

    if (response?.code === 200) {
        if(response?.body[0].service_name === 'QDI') {
            return response.body[0];
        } else {            
            throw new Error("Unhandled response.")
        }
    }
    else {
        throw new Error("Unhandled response.")
    }
}

/**
 * If not a 200 response, throws an Error subclass.
 * 
 * @param response APIJSONResponse instance from api-bridge call.
 * @returns 
 */
function handleDocumentVerificationResponse(response:APIJSONResponse) {
    if (200 == response.code) {
        return true;
    }
    else if (409 == response.code) {
        throw new DocumentAlreadyVerifiedError();
    } 
    else if (404 == response.code || 400 == response.code) {
        throw new DocumentVerificationError(response.body.Results[0]?.ErrorResponse.Indicator, 
            response.body.Results[0]?.ErrorResponse.Description);
    }

    throw new Error("Unhandled response.");
}
