import CriticalAlert from "../components/swe/critical-alert";
import {DEBUG} from "../constants";

const InvalidRelyingParty = () => {

  const ref = sessionStorage.getItem('QDI-RP');
  if (DEBUG) {console.log('Unauthorised referer %o. If /invalidRelyingParty then header contained no referrer.', ref);}

  //removes any previously set RP so we dont concat to existing if user types in another url
  sessionStorage.removeItem("QDI-RP");

  return (
    <>
      <div>
        <h1>Queensland Digital Identity</h1>
        <div tabIndex={-1}>
          <CriticalAlert heading="Unauthorised Access" message={
            <div>
              <p>Access to the Queensland Digital Identity is restricted to approved systems and networks.</p>
              <p> The link or application you have used to try to access the Queensland Digital Identity is not approved.</p>
            </div>
          } />
        </div>
      </div>
    </>
  )
};

export default InvalidRelyingParty;