import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../../constants";
import useGlobalState from "../../hooks/useGlobalState";

import LicenceDocumentForm from "../../components/qdi/form/licence-document-form";
import Product from "../../components/qdi/product";
import { verifyDriversLicence } from "../../services/x-api"
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import { extractIpn, extractCl } from "../../utils/helpers";

export const DriversLicenceForm = () => {
  const navigate = useNavigate();
  const { globalState, saveGlobalState } = useGlobalState();
  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();


  useEffect(() => {
    (async () => {

      try {
        if (!isAuthenticated) {
          // Throw exception if user not authenticated
          throw new Error("User is not authenticated!");
        }
        await getAccessTokenSilently({ ignoreCache: true });
        const claims = await getIdTokenClaims();
        const ipn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
        const acr = user["http://oauth.tmr.qld.gov.au"].acr;

        if (Constants.DEBUG) {
          console.log('user claims  ipn ->', ipn);
          console.log('user object acr ->', acr);
          console.log('%c[DriversLicenceForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
            isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
        }
        
        saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
        if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

        // Check if user should be here
        let mfaAvailable = globalState.userCompletedMFA;
        if (typeof mfaAvailable === 'undefined' || globalState.sessionToken.ipn !== "ip1" || (user && user.email_verified === false)) {
          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
          navigate("/ip-uplift", { replace: true });
        }
      }
      catch (error) {
        if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        window.location.href = rp;
      }

    })();
  }, []);

  return (
    <>
      {isAuthenticated &&
        <div>
          <h1>Verify your first credential</h1>

          <LicenceDocumentForm
            id="Queensland Driver Licence"
            instructions={<p>Enter your information, exactly as it appears on your Queensland Driver Licence</p>}
            product={<Product heading="Queensland Driver Licence" media={require('../../assets/images/driverlicence.jpg')} altText="QLD Drivers Licence" action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />}
            backNavigateTo="/ip-uplift/verify-your-identity"
            nextNavigateTo='/ip-uplift/select-other-document'
            typeCode="DL"
            identifierType="CRN"
            action={verifyDriversLicence}
          />
        </div>
      }
    </>)
};

export default DriversLicenceForm;