import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";
import useGlobalState from "../hooks/useGlobalState";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import { extractIpn } from "../utils/helpers";

import SuccessAlert from "../components/swe/success-alert";
import WarningAlert from "../components/swe/warning-alert";
import Product from "../components/qdi/product";
import SecondaryButton from "../components/swe/secondary-button";

export const SelectOtherDocument = () => {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const focusRef = useRef(null);

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const ipn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);

                if (Constants.DEBUG) {
                    console.log('user claims  ipn ->', ipn);
                    console.log('%c[SelectOtherDocument] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Check if user should be here
                let mfaAvailable = globalState.userCompletedMFA; 
                if (typeof mfaAvailable === 'undefined' || globalState.sessionToken.ipn !== "ip1p" || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                window.location.href = rp;
            }

        })();
    }, []);

    useEffect(() => {
        focusRef?.current.focus();
    }, []);

    const [cancelledOther, setCancelledOther] = useState(false);

    function cancelOther() {
        if (!cancelledOther) {
            setCancelledOther(true);
        }
        else {
            window.location.href = new RelyingPartyBuilder()
                .withRelyingParty(Constants.TMP_DLA_URI)
                .withAcValue("IP1P:CL2")
                .withReturnCode(7)
                .build();
        }
    }

    const displayPassportForm = () => {
        navigate("/ip-uplift/select-other-document/passport-form", { replace: true });
    }

    const displayBirthCertificateForm = () => {
        navigate("/ip-uplift/select-other-document/birth-certificate-form", { replace: true });
    }

    const displayVisaForm = () => {
        navigate("/ip-uplift/select-other-document/visa-form", { replace: true });
    }

    const displayCitizenshipForm = () => {
        navigate("/ip-uplift/select-other-document/citizenship-certificate-form", { replace: true });
    }

    const displayImmiCardForm = () => {
        navigate("/ip-uplift/select-other-document/immicard-form", { replace: true });
    }

    return (
        <>
            {isAuthenticated &&
                <div>
                    <h1>Verify your second credential</h1>
                    <div tabIndex={-1} ref={focusRef}></div>

                    <SuccessAlert message={<><p>You have successfully verified your first credential.</p><p>You will now need to verify a second credential.</p></>} />

                    <p>Please select one credential that you have from the list below:</p>

                    <section className="row cards-identity qg-cards">
                        <Product
                            heading="Australian Passport"
                            media={require('../assets/images/Australian Passport.png')}
                            action={displayPassportForm}
                        />
                        <Product
                            heading="Australian Birth Certificate"
                            media={require('../assets/images/AustralianBirthCertificate.png')}
                            action={displayBirthCertificateForm}
                            imgStyles={{ border: '1px solid #707070' }}
                        />
                        <Product
                            heading="Foreign passport with valid visa or entry stamp"
                            media={require('../assets/images/Australian Visa.png')}
                            action={displayVisaForm}
                            imgStyles={{ border: '1px solid #707070' }}
                        />
                        <Product 
                            heading="Australian Citizenship Certificate or Australian Citizenship of Descent" 
                            media={require('../assets/images/Australian Citizenship Certificate or Citizenship of Descent.png')} 
                            action={displayCitizenshipForm} 
                            imgStyles={{border: '1px solid #707070'}}
                        />
                        <Product 
                            heading="ImmiCard - Evidence of Immigration Status or Australian Migration Status" 
                            media={require('../assets/images/Immigration Status or Australian Migration Status.png')} 
                            action={displayImmiCardForm} 
                            imgStyles={{border: '1px solid #707070'}}
                        />
                    </section>

                    {cancelledOther ? <WarningAlert heading="Sure you want to cancel?" message={<><p>If you cancel, you will be returned to the service page, and your identity will not be verified. You will be able to verify your identity later by logging in using your email and password, and continuing this process.</p><p>Select Cancel if you wish to cancel this process.</p></>} /> : null}

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <SecondaryButton id="Cancel" heading="Cancel" action={cancelOther} />
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>)
};

export default SelectOtherDocument;