import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { AppProvider } from "./context/AppContext";
import * as Constants from "./constants";

const onRedirectCallback = (appState) => {
  if (Constants.DEBUG) { console.log('appState:%o\n', appState); }

  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const providerConfig = {
  domain: Constants.AUTH0_URI,
  clientId: Constants.AUTH0_CLIENTID,
  ...(Constants.API_URI ? { audience: Constants.API_URI } : null),
  redirectUri: `${window.location.origin}/callback`,
  ip_uplift_url: `${window.location.origin}/ip-uplift`,
  acr_values: Constants.DEFAULT_ACR,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,  
  scope: 'update:add_identity_proofing_doc identity_proofing_number offline_access dla_info',
  crossOriginAuthentication: true,
  onRedirectCallback,
};

ReactDOM.render(
  <AppProvider>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </AppProvider>,
  document.getElementById("app-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
