import React from 'react';

/**
 * 
 * @param heading: bold short text to display next to icon. optional.
 * @param message: text to display.
 * @param type: css class to be passed in from wrapping component that will colour the alert.
 * @param icon: icon to display top left. optional.
 * @param showIcon: flag to show icon or not. optional. defaults to true.
 */
type Props = {
    heading?: string,
    message: any,
    type: "alert-info" | "alert-warning" | "alert-danger" | "alert-success",
    icon?: string,
    showIcon?: boolean,
    testId?: string,
};

function BaseAlert({heading, message, type, icon, showIcon=true, testId} : Props) {
    let attributes: any = {};
    if (testId) { attributes['data-testid'] = testId; }
    return (
        <div className={'my-3 alert ' + type} role="alert"{...attributes}>
            {
                heading
                ? 
                    <>
                        <h2>
                            <span className={'fa ' + icon}></span> {heading}
                        </h2>
                        <div>
                            {
                                (typeof message === 'string') ? <p>{message}</p> : <>{message}</>
                            }
                        </div>
                    </>
                : 
                    <>
                        <h2 style={{float: 'left'}}>{icon && <span className={`fa ${/^fa-/.test(icon)? icon : ['fa', icon].join('-')}`}></span>}</h2>
                        {
                            (typeof message === 'string') ? <span>{message}</span> : <>{message}</>
                        }
                    </>
            }
        </div>
    );
}

export default BaseAlert;