import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useGlobalState from "../hooks/useGlobalState";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import {extractCl, extractIpn} from "../utils/helpers";

import CriticalAlert from '../components/swe/critical-alert';
import PrimaryButton from '../components/swe/primary-button';

export const EmailAlreadyUsed = () => {
    const { globalState, saveGlobalState } = useGlobalState();
    const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();
    const alertRef = useRef(null);
    let clValue:string|null;

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();

                if (Constants.DEBUG) {
                    console.log('%cisAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                const ipn = extractIpn(claims && claims['http://oauth.tmr.qld.gov.au'].acr);
                clValue = extractCl(claims && claims['http://oauth.tmr.qld.gov.au'].acr);
                if (Constants.DEBUG) { console.log('user claims  ipn ->', ipn); }
                const sessionToken = localStorage.getItem('session_token');
                saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
                if(globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Test if authenticated user has already verified email
                if (user && user.email_verified) {
                    console.log('User already verified their email. Redirecting to uplift...');
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                if (Constants.DEBUG) {
                    console.log('%c%s', 'color: #c00;', error);
                }
                const rp = new RelyingPartyBuilder()
                    .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
                    .build();
                window.location.href = rp;
            }
        })();
    }, []);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);


    function cancelQDI() {
        window.location.href = new RelyingPartyBuilder()
            .withRelyingParty(Constants.TMP_DLA_URI!)
            .withReturnCode(4)
            .withAcValue(`IP1:${clValue}`)
            .build();
    }

    return (
        <>
            {isAuthenticated && 
                <div>
                    <h1>Queensland Digital Identity</h1>

                    <div tabIndex={-1} ref={alertRef}>
                        <CriticalAlert heading="An unexpected error has occurred." message={<p>Email address already in use. <br/> Please <a href={Constants.HELP_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>} />
                    </div>

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id="submitButton" heading="Close" action={cancelQDI} />
                                </ul>
                            </li>
                        </ol>
                    </div>
            	</div>
		    }
        </>
    )
};

export default EmailAlreadyUsed;