import * as Constants from "../constants"
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState, useRef } from "react";
import Loading from "../components/Loading";

const Login = () => {
    const { isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0();
    const [loginStatusChecked, setLoginStatusChecked] = useState(null);
    const [QDIStatus, setQDIStatus] = useState('');

    const sleep = m => new Promise(r => setTimeout(r, m))

    useEffect(()=>{
        async function checkServiceStatusSession() {
            if (sessionStorage.getItem('QDI-RP') !== Constants.INVALID_RELYING_PARTY_REDIRECT && sessionStorage.getItem("QDI-Status") !== null) {
                while(sessionStorage.getItem("QDI-Status") !== "UP" && sessionStorage.getItem("QDI-Status") !== "DOWN") {
                    console.log('Waiting for QDI Service Status..');
                    await sleep(1000);
                }
                console.log('QDI Service Status recieved.');
                setQDIStatus(sessionStorage.getItem('QDI-Status'));
            }
        }

        checkServiceStatusSession();
    },[]);

    useEffect(() => {
        async function logoutSilently() {
            if (Constants.DEBUG) console.log('Check for logged in user and logout if present...');
            if (isAuthenticated) {
                const idToken = await getIdTokenClaims();
                if (idToken) {
                    if (Constants.DEBUG) console.log('silently logging out user', idToken);
                    const logoutUrl = `${process.env.react_app_auth0_uri}/v2/logout?id_token_hint=${idToken.__raw}&post_logout_redirect_uri=${window.location.origin}`;
                    const iframe = document.createElement('iframe');
                    iframe.src = logoutUrl;
                    iframe.style.display = 'none';
                    document.body.appendChild(iframe);
                    await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for the logout to complete
                    document.body.removeChild(iframe);
                    if (Constants.DEBUG) console.log('logged out...');
                    // Optionally, perform additional cleanup or actions after logout
                }
            }
            setLoginStatusChecked(true);
        };
        logoutSilently();
    }, []);

    useEffect(() => {

        if (!loginStatusChecked || !(QDIStatus === 'UP')) { // dont execute until we've cleared any existing logins and if the QDI service not available.
            return;
        }

        const referringUrl = sessionStorage.getItem('QDI-RP');
        
        let queryParams = new URLSearchParams(window.location.search);
        let requestedAcr = queryParams.get("acr");
        let allowedACRArray = (Constants.ALLOWED_ACR) ? Constants.ALLOWED_ACR.split(',') : [];

        if (Constants.DEBUG) console.log('Clear local and session storage...');
        localStorage.clear();
        sessionStorage.clear();
        window.sessionStorage.setItem("QDI-IP", requestedAcr);
        window.sessionStorage.setItem("QDI-RP", referringUrl);
        window.sessionStorage.setItem("QDI-Status", QDIStatus);

        if (!requestedAcr) {
            if (Constants.DEBUG) console.log('Logging in with default ACR');
            loginWithRedirect({ hint: "prompt", acr_values: Constants.DEFAULT_ACR })
        }
        else if (allowedACRArray.includes(requestedAcr)) {
            if (Constants.DEBUG) console.log('Logging in with requested ACR,',Constants.IP_MAPPINGS[requestedAcr]);
            loginWithRedirect({ hint: "prompt", acr_values: Constants.IP_MAPPINGS[requestedAcr] })
        } else if (requestedAcr) {
            if ((window.location.href === document.referrer) || !document.referrer) {
                throw new Error();
            } else {
                window.location.assign(document.referrer + "?returnCode=12");
            }
        }
    }, [loginStatusChecked, QDIStatus]);

    return (
        <Loading />
    )
};

export default Login;
