import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import WarningAlert from "../components/swe/warning-alert";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";
import useGlobalState from "../hooks/useGlobalState";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import { TMP_DLA_URI } from "../constants";
import { Call, VERBS } from "../services/api-bridge";
import "../assets/css/qdi-sandbox.css";
import { useNavigate } from "react-router-dom";
import * as Constants from "../constants";

export const TermsAndConditions = () => {
  const [firstTCChecked, setFirstTCChecked] = useState(false);
  const [secondTCChecked, setSecondTCChecked] = useState(false);
  const [declineTCConfirm, setDeclineTCConfirm] = useState(false);
  const { globalState, saveGlobalState } = useGlobalState();

  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!isAuthenticated) {
          // Throw exception if user not authenticated
          throw new Error("User is not authenticated!");

          // TODO : prevent authorised user re-displaying this page
          //        if already accepted latest Ts&Cs
        }
      } catch (error) {
        // Redirect user to the default RP of DLA Eligibility app
        // TODO : What to do if no RP?
        console.log('%c%s', 'color: #c00;', error);
        const rp = new RelyingPartyBuilder()
          .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
          .build();
        console.log("Redirecting unauthenticated user to ", rp);
        window.location.href = rp;
      }
    })();
  }, []);

  function declineTCPrompt() {
    if (declineTCConfirm) {
      window.location.href = new RelyingPartyBuilder()
        .withRelyingParty(TMP_DLA_URI)
        .withReturnCode(1)
        .build();
    }
    if (firstTCChecked || secondTCChecked) {
      setFirstTCChecked(false);
      setSecondTCChecked(false);
    }
    setDeclineTCConfirm(true);
  }

  async function tAndcAccepted() {

    // Call X-API to set tandc_accepted timestamp
    const accesstoken = await getAccessTokenSilently({ ignoreCache: true });
    const idToken = await getIdTokenClaims();

    let body = {
      "DLAUserAccount": {
        "user_id": idToken.sub
      }
    }
    const returned = await Call('qdi/users/identity/tandc', VERBS.POST, body, accesstoken);
    if (200 == returned.code) {

      // Update our copy of the globalState.sessionToken.tanc_accepted value as we only get the
      // the value once when we log in and are given the session token
      //
      // Ideally, for a 200 response we should recieve the exact timestamp the API set
      // so we dont have to create our own which will obviously be a few millis out
      const userAcceptance = new Date().toISOString().split('.')[0] + '.000Z';

      let sessionTokenJSON = JSON.parse(localStorage.getItem('session_token'));
      sessionTokenJSON.tandc_accepted = userAcceptance;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
      saveGlobalState({ sessionToken: sessionTokenJSON });

      if (Constants.DEBUG) { console.log("User %o just successfully accepted Ts&Cs on %o", idToken.sub, userAcceptance); }

    } else {
      throw new Error("Invalid response.");
    }

    // Navigate back to uplift to continue uplift or MFA enrolment
    navigate("/ip-uplift", { replace: true });
  }

  return (
    <>
      {isAuthenticated && (
        <form className="qg-forms-v2">
          <h1 id="terms-and-conditions">Terms and Conditions</h1>

          {globalState.sessionToken?.tandc_accepted && (
            <WarningAlert heading="Terms and Conditions updated"
              message={<>
                <p>We have recently update the Terms and Conditions for the Queensland Digital Identity (QDI)</p>
                <p>Please accept them to continue using your QDI</p>
              </>} />
          )}

          <div className="content-scrollable-vh40">
            <div id="content-privacy" className="sandbox">
              <ol aria-labelledby="terms-and-conditions">
                <li>
                  <h2><span className="li-num">1.	</span>About QDI (Queensland Digital Identity)</h2>
                  <ol type="1">
                    <li><span className="li-num">1.1</span>	QDI is an identity service provider - a service that creates, stores and manages the use of Digital Identities. QDI is administered by the State of Queensland acting through the Department of Transport and Main Roads (TMR).</li>
                    <li><span className="li-num">1.2</span>	These Terms of Use, including <a href="https://www.publications.qld.gov.au/dataset/digital-licence-app" target="_blank" rel="noopener">Privacy Statement</a> which forms part of these Terms of Use, apply to your use of and access to QDI.</li>
                    <li><span className="li-num">1.3</span>	These Terms of Use may change over time. By accessing or using QDI you agree to be bound by the applicable Terms of Use, as published on this page at that time.</li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">2.</span>	Your QDI Digital Identity</h2>
                  <ol type="1">
                    <li>
                      <h3><span className="li-num">2.1</span>	Getting Started</h3>
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	Using QDI, you:
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	can establish a Digital Identity; and</li>
                            <li><span className="li-num">(ii)</span>	may choose to provide TMR with details of your Identity Documents to establish higher levels of identity proofing for your Digital Identity.</li>
                          </ol>
                        </li>
                        <li><span className="li-num">(b)</span>	If you provide details of your Identity Documents via QDI:
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	TMR will check those details against the TMR system of record (for TMR-issued Identity Documents), and the Document Verification Service (for other government-issued Identity Documents); and</li>
                            <li><span className="li-num">(ii)</span>	your Digital Identity will be updated on QDI once your Identity Documents are verified.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h3><span className="li-num">2.2</span>	What you can use it for</h3>
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	You can use your Digital Identity to 'prove who you are' online, for example in online dealings with Relying Parties such as government agencies, online retailers or other service providers.</li>
                        <li><span className="li-num">(b)</span>	Using QDI, you can:
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	give your specific consent for a Relying Party to access your Digital Identity to verify your identity, or some attributes associated with your identity such as your age; or</li>
                            <li><span className="li-num">(ii)</span>	access the services of multiple Relying Parties using your Digital Identity to authenticate.</li>
                          </ol>
                        </li>
                        <li><span className="li-num">(c)</span>	Some Relying Parties may require a higher level of identity proofing for certain transactions, and you can upgrade your Digital Identity at any time by providing the required Identity Documents.</li>
                        <li><span className="li-num">(d)</span>	TMR provides QDI free of charge to individual users who are residents of Australia.</li>
                      </ol>
                    </li>
                    <li>
                      <h3><span className="li-num">2.3</span>	TMR responsibilities</h3>
                      <p>TMR will take reasonable care in providing QDI. We are responsible for ensuring the security of information collected by, stored on or processed by QDI.</p>
                    </li>
                    <li>
                      <h3><span className="li-num">2.4</span>	Changes</h3>
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	TMR may from time to time change the process to identify you, levels of identity proofing available through QDI, the required Identity Documents, or its submission and verification processes, without notice to you. You may be required to provide any additional personal information or consents required, or to perform any new or altered processes to continue accessing QDI services.</li>
                        <li><span className="li-num">(b)</span>	TMR may at any time and without notice to you, suspend or make amendments to your Digital Identity where reasonably necessary to correct an error, or to deal with a security risk, or breach or threatened breach of these Terms of Use.</li>
                        <li><span className="li-num">(c)</span>	TMR may, in its sole discretion and without notice:
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	issue updates or new versions of QDI;</li>
                            <li><span className="li-num">(ii)</span>	modify, or suspend or discontinue QDI or any part of its functionality;</li>
                            <li><span className="li-num">(iii)</span>	limit or restrict your access to certain QDI features and services; or</li>
                            <li><span className="li-num">(iv)</span>	correct any errors or omissions in QDI or any data or information it contains or provides.</li>
                          </ol>
                        </li>
                        <li><span className="li-num">(d)</span>	QDI may be unavailable during scheduled and unscheduled maintenance. TMR may undertake maintenance activities without notice.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">3.</span>	Your personal information</h2>
                  <ol type="1">
                    <li>
                      <h3><span className="li-num">3.1</span>	Your Personal Information</h3>
                      <p>By providing TMR with your personal information in the course of establishing your Digital Identity and using QDI you agree that we can store your personal information, including your name, date of birth and details of your Identity Documents. You will retain control of your personal information and how it is shared. Please ensure you read and agree to the QDI <a href="https://www.publications.qld.gov.au/dataset/digital-licence-app" target="_blank" rel="noopener">Privacy Statement</a>, which forms part of this Agreement.</p>
                    </li>
                    <li>
                      <h3><span className="li-num">3.2</span>	No tracking</h3>
                      TMR will not identify users or track users' activities in QDI except:
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	as stated in these Terms of Use or the QDI <a href="https://www.publications.qld.gov.au/dataset/digital-licence-app" target="_blank" rel="noopener">Privacy Statement</a>;</li>
                        <li><span className="li-num">(b)</span>	where otherwise reasonably necessary to operate QDI; and</li>
                        <li><span className="li-num">(c)</span>	where TMR is required or authorised by law to do so.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">4.</span>	What we ask of you</h2>
                  <ol type="1">
                    <li>
                      <h3><span className="li-num">4.1</span>	Your security obligations</h3>
                      <p>You agree to:</p>
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	keep your Digital Identity details confidential and secure and not to permit any other person to use your Digital Identity;</li>
                        <li><span className="li-num">(b)</span>	immediately change your Digital Identity details if you believe that those details have been compromised;</li>
                        <li><span className="li-num">(c)</span>	not access anyone else's Digital Identity unless clause 4.2(b) applies;</li>
                        <li><span className="li-num">(d)</span>	keep all private information relating to your Digital Identity, such as your username and password, secure at all times; and</li>
                        <li><span className="li-num">(e)</span>	let us know immediately if you suspect that the security of your Digital Identity may have been compromised, including any security compromise relating to the username and password required to access your Digital Identity, or any biometric or device security feature such as face scan or fingerprint, and any multi-factor authentication processes, that you may have consented to add to your Digital Identity.</li>
                      </ol>
                    </li>
                    <li>
                      <h3><span className="li-num">4.2</span>	Lawful purposes</h3>
                      <ol type="a">
                        <li>
                          <p><span className="li-num">(a)</span>	You must use QDI only for lawful purposes and in a manner that does not infringe the rights of others or restrict or inhibit the operation or use of the QDI System. This includes refraining from conduct which:</p>
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	is unlawful, fraudulent misleading or deceptive, including impersonating another person, whether living or deceased;</li>
                            <li><span className="li-num">(ii)</span>	may harass or cause distress or inconvenience to any person;</li>
                            <li><span className="li-num">(iii)</span>	involves the transmission of obscene or offensive content; or</li>
                            <li><span className="li-num">(iv)</span>	disrupts the QDI System.</li>
                          </ol>
                        </li>
                        <li>
                          <p><span className="li-num">(b)</span>	If you:</p>
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	use or access QDI using the Digital Identity or personal information belonging to another person or entity; and/or</li>
                            <li><span className="li-num">(ii)</span>	use another person's Digital Identity to authenticate to a Relying Party's service, then you confirm that you have the necessary authority to act on behalf of that other person or entity.  You must only use or access QDI on behalf of another person or entity within the scope of authorisation granted by that third party.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">5.</span>	Intellectual property</h2>
                  <ol type="1">
                    <li>
                      <h3><span className="li-num">5.1</span></h3>
                      <p>All intellectual property rights in QDI and the data generated or produced via QDI, are owned by TMR or relevant third party rights holders. You must not:</p>
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	use, reproduce, create derivative works from, sell or distribute QDI except as allowed in these Terms of Use; or</li>
                        <li><span className="li-num">(b)</span>	use trade marks or branding belonging to TMR or any third parties, without written permission.</li>
                      </ol>
                    </li>
                    <li>
                      <h3><span className="li-num">5.2</span></h3>
                      <p>QDI may include open source or third party software, and your use of QDI is subject to any licences governing such software.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">6.</span>	Disclaimers and indemnity</h2>
                  <ol type="1">
                    <li>
                      <h3><span className="li-num">6.1</span>	Your rights</h3>
                      Nothing in this clause 6 or any other part of this Agreement excludes any right or guarantee the Customer may have under Schedule 2 of the Competition and Consumer Act 2010 (Cth) and any equivalent state or territory legislation or other rights in relation to the supply of goods or services that cannot lawfully be excluded (Non-excludable Guarantee).
                    </li>
                    <li>
                      <h3><span className="li-num">6.2</span>	Disclaimers</h3>
                      <ol type="a">
                        <li>
                          <span className="li-num">(a)</span>	You should exercise your own skill and care with respect to the use of QDI. QDI is provided on an 'as is' basis, and to the maximum extent permitted by law, TMR makes no statement, representation, or warranty:
                          <ol type="i">
                            <li><span className="li-num">(i)</span>	about the quality, accuracy, context, completeness, availability or suitability for any purpose of QDI or your Digital Identity;</li>
                            <li><span className="li-num">(ii)</span>	that your Digital Identity will be accepted by any particular Relying Party;</li>
                            <li><span className="li-num">(iii)</span>	that the QDI System is free of malware or security vulnerabilities or available for use at any particular time without interruption; or</li>
                            <li><span className="li-num">(iv)</span>	that QDI will meet your specific requirements.</li>
                          </ol>
                        </li>
                        <li><span className="li-num">(b)</span>	TMR does not accept liability for any loss, damage, cost or expense (to any person or property) including consequential or indirect loss or any loss of profits, data or revenue that could arise as a result of your use of QDI or your Digital Identity.</li>
                        <li><span className="li-num">(c)</span>	TMR is entitled to assume that all transactions undertaken using your Digital Identity were undertaken by you or with your permission, unless you have informed us that your Digital Identity has been compromised.  TMR will not be liable for any loss or damage that you may incur as a result of someone else using your Digital Identity, either with or without your knowledge or consent.</li>
                        <li><span className="li-num">(d)</span>	Any failure by you to keep your Digital Identity secure may result in unauthorised transactions being undertaken or unauthorised access to and use of your  Digital Identity.</li>
                        <li><span className="li-num">(e)</span>	You remain solely responsible for your dealings with any Relying Party. TMR accepts no responsibility in connection with any action or inaction of any Relying Party or the systems or services of any Relying Party. Each Relying Party may require you to agree to their terms of use in connection with your use of that Relying Party website.</li>
                      </ol>
                    </li>
                    <li>
                      <h3><span className="li-num">6.3</span>	Indemnity</h3>
                      <p>You agree to indemnify TMR and our officers, employees, agents and contractors ("those indemnified") from and against all actions, proceedings, claims demands, costs (including all reasonable legal costs), losses, damages and expenses, and any direct, indirect, incidental or consequential loss or damage, including those arising out of the terms of any settlement, which:</p>
                      <ol type="a">
                        <li><span className="li-num">(a)</span>	may be brought against or made upon those indemnified, and</li>
                        <li><span className="li-num">(b)</span>	those indemnified may incur on their own behalf or sustain as a result of a third party claim, arising out of or as a consequence of:</li>
                        <li><span className="li-num">(c)</span>	your breach of these Terms of Use, or</li>
                        <li><span className="li-num">(d)</span>	any wilful, unlawful, or negligent act or omission by you relating in any way to the QDI System.</li>
                      </ol>
                    </li>
                    <li>
                      <h3><span className="li-num">6.4</span>	Capped Liability</h3>
                      <p>To the fullest extent permitted by law, TMR's liability under this Agreement, including TMR's liability under any applicable Non-excludable Guarantee, is limited, at the TMR's option, to the supply of the relevant services again, or the payment of the cost of having the relevant services supplied again.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">7.</span>	Termination</h2>
                  <ol type="a">
                    <li><span className="li-num">(a)</span>	You may delete your Digital Identity at any time in which case it will be permanently deactivated. You will not be able to reactivate your deleted Digital Identity, and will be required to establish your Digital Identity again should you wish to reactivate it.</li>
                    <li><span className="li-num">(b)</span>	TMR may immediately suspend your Digital Identity at any time if we suspect that your Digital Identity is involved in a security breach or fraudulent or illegal activity.</li>
                    <li><span className="li-num">(c)</span>	TMR may at any time and for any reason, and without notice to you, restrict, suspend or terminate your access to QDI in whole or in part, without notice, or terminate this agreement and delete your Digital Identity.</li>
                    <li><span className="li-num">(d)</span>	To the extent applicable, the <a href="https://www.publications.qld.gov.au/dataset/digital-licence-app" target="_blank" rel="noopener">Privacy Statement</a> and clauses 6 and 8 of these Terms of Use will survive termination of this agreement for any reason.</li>
                  </ol>
                </li>
                <li>
                  <h2><span className="li-num">8.</span>	General</h2>
                  <ol type="1">
                    <li>
                      <h3><span className="li-num">8.1</span>	Contact</h3>
                      <p>TMR is available for contact:</p>
                      <h4>Phone: </h4>
                      <a href="tel:13+23+80">13 23 80</a>
                      <h4>Email: </h4>
                      <a href="mailto:digitallicence@tmr.qld.gov.au">digitallicence@tmr.qld.gov.au</a>
                      <h4>Mail:</h4>
                      <h5>General business</h5>
                      <address>
                        PO Box 673<br />
                        Fortitude Valley Qld 4006
                      </address>
                      <h5>Compliments and complaints</h5>
                      <address>
                        <strong>Department of Transport and Main Roads</strong><br />
                        GPO Box 1412<br />
                        Brisbane Qld 4001
                      </address>
                    </li>
                    <li>
                      <h3><span className="li-num">8.2</span>	Governing law</h3>
                      <p>These Terms of Use are governed by and are to be construed in accordance with the laws applicable in Queensland. Each party submits to the jurisdiction of the courts of Queensland.</p>
                    </li>
                    <li>
                      <h3><span className="li-num">8.3</span>	Entire agreement</h3>
                      <p>These Terms of Use constitute the whole agreement between you and TMR in relation to QDI and your Digital Identity, and supersedes all prior representations, agreements, statements and understandings, whether verbal or in writing.</p>
                    </li>
                    <li>
                      <h3><span className="li-num">8.4</span>	Severance</h3>
                      <p>If any provisions of these Terms of Use are held to be invalid, unenforceable or illegal for any reason, then it will be severed from these Terms of Use and the remaining provisions will continue in full force.</p>
                    </li>
                    <li>
                      <h3><span className="li-num">8.5</span>	Waiver</h3>
                      <p>No provision of these Terms of use will be taken to be waived except by written waiver executed between you and TMR.</p>
                    </li>
                    <li>
                      <h3><span className="li-num">8.6</span>	Definitions</h3>
                      <p>In these Terms of Use:</p>
                      <dl>
                        <dt>Digital Identity</dt>
                        <dd>means a digital identity established using QDI</dd>
                        <dt>Document Verification Service</dt>
                        <dd>means the service provided by the Australian Government known as the Document Verification Service (DVS). DVS check whether the biographic information on your Identity Document matches the original record. More information can be found at <a href="https://www.idmatch.gov.au/" target="_blank" rel="noopener">https://www.idmatch.gov.au/</a>.
                          Identity Document	means documents which help prove who you are, such as your driver's licence, Australian passport, birth certificate and other eligible documents listed in QDI.</dd>
                        <dt>QDI</dt>
                        <dd>means the digital identity service provided by TMR known as QDI.</dd>
                        <dt>QDI System</dt>
                        <dd>means the software and IT systems and infrastructure which TMR uses to provide the QDI service.</dd>
                        <dt>Relying Party</dt>
                        <dd>means a person or entity which uses your Digital Identity to authenticate into their systems or services, or who seeks attributes associated with your Digital Identity as part of the delivery of a service to you. Relying Parties could be, for example, government agencies, online retailers or other service providers or entities you are dealing with online.</dd>
                      </dl>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>


          <ol className="questions">
            <li>
              <fieldset>
                <legend>
                  <span className="label"></span>
                </legend>
                <ul className="choices qg-forms-v2__checkbox">
                  <li>
                    <input
                      type="checkbox"
                      id="t-and-c"
                      autoComplete="off"
                      checked={firstTCChecked}
                      onChange={(e) => setFirstTCChecked(e.target.checked)}
                    />
                    <label htmlFor="t-and-c">
                      <span className="label">
                        <abbr className="required">*</abbr>
                        I have read the Terms and Conditions</span>
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="other-t-and-c"
                      autoComplete="off"
                      checked={secondTCChecked}
                      onChange={(e) => setSecondTCChecked(e.target.checked)}
                    />
                    <label htmlFor="other-t-and-c">
                      <span className="label">
                        <abbr className="required">*</abbr>
                        I have read and agree to the &nbsp;
                        <a
                          target="_blank"
                          rel="noopener"
                          className="tmr-link"
                          href="https://www.tmr.qld.gov.au/Help/Privacy">
                          Privacy Policy</a>, &nbsp;
                        <a
                          target="_blank"
                          rel="noopener"
                          className="tmr-link"
                          href="https://www.publications.qld.gov.au/dataset/digital-licence-app">
                          Privacy Notice
                        </a>, and the provision of my private information to be used for the
                        Queensland Digital Identity
                      </span>
                    </label>
                  </li>
                </ul>{(declineTCConfirm) && (
                  <WarningAlert heading="Declining Terms and Condition" message={<><p>If you decline the Terms and Conditions, or the Privacy Policy or Privacy Notices, you will not be able to create a Queensland Digital Identity.</p><p>You will be returned to the service page, and will be able to start the process again if you wish.</p><p>Select Decline again if you wish to decline the Terms and Conditions, Privacy Policy or Privacy Notice.</p></>} />
                )}
              </fieldset>
            </li>
            <li className="footer">
              <ul className="actions">
                <PrimaryButton id='Accept' heading='Accept' action={tAndcAccepted} disabled={!firstTCChecked || !secondTCChecked} />
                <SecondaryButton id="Decline" heading="Decline" action={() => declineTCPrompt()} />
              </ul>
            </li>
          </ol>
        </form>
      )}

    </>
  );
};

export default TermsAndConditions;
