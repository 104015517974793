//import { useAuth0 } from "@auth0/auth0-react";
import CriticalAlert from "../components/swe/critical-alert";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from '../constants'
import { useRef, useEffect } from "react";
import useGlobalState from "../hooks/useGlobalState";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Logout = () => {

  // Currently, this page is no longer used but still accessible via /logout
  // It has been refactored to enable a simple logout if required.
  // It was originally used as a redirect when session-timout and logging out user
  // but this is now all done in the actual sessionTimeout page.

  const { globalState } = useGlobalState();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function logoutSilently() {
      if (isAuthenticated) {
        const idToken = await getIdTokenClaims();
        if (idToken) {
          if (Constants.DEBUG) console.log('silently logging out user', idToken);
          const logoutUrl = `${process.env.react_app_auth0_uri}/v2/logout?id_token_hint=${idToken.__raw}&post_logout_redirect_uri=${window.location.origin}`;
          const iframe = document.createElement('iframe');
          iframe.src = logoutUrl;
          iframe.style.display = 'none';
          document.body.appendChild(iframe);
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for the logout to complete
          document.body.removeChild(iframe);
          if (Constants.DEBUG) console.log('logged out...');
          // Optionally, perform additional cleanup or actions after logout
        }
      }
    };
    logoutSilently();
  }, []);

  return (<>
    <div>
        <h1>You have been logged out</h1>
        <div style={{ marginBottom: '150px' }} />
    </div>
</>);

};

export default Logout;