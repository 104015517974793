import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CriticalAlert from "../components/swe/critical-alert";
import * as Constants from "../constants"
import { useSearchParams } from "react-router-dom";

const ServiceUnavailable = () => {
    const { isAuthenticated } = useAuth0();
    const alertRef = useRef(null);
    const [searchParams] = useSearchParams();
    const message = searchParams.get("message");

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    return (
        <>
            <div>
                <h1>Queensland Digital Identity</h1>
                <div>
                    <CriticalAlert 
                        heading="Queensland Digital Identity is currently unavailable"
                        message={<> {message && message !== 'undefined' ? <p>{message}</p> : <p>The Department of Transport and Main Roads apologises for any inconvenience.</p>}<p>If you require immediate assistance please <a href={Constants.HELP_URL}>contact us</a>.</p></>}
                    />
                </div>
            </div>
        </>
    )
}

export default ServiceUnavailable;