import React, { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../constants";
import useGlobalState from "../../hooks/useGlobalState";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import {extractIpn} from "../../utils/helpers";

import TravelDocumentForm from "../../components/qdi/form/travel-document-form";
import Product from "../../components/qdi/product";
import { verifyVisa } from "../../services/x-api";

export const VisaForm = () => {
    const { globalState, saveGlobalState } = useGlobalState();
    const { getAccessTokenSilently, isAuthenticated, user, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();
    const focusRef = useRef(null);

    window.sessionStorage.setItem("QDI-IPE", "IP1P:CL1");

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const ipn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);

                if (Constants.DEBUG) {
                    console.log('user claims  ipn ->', ipn);
                      console.log('%c[VisaForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
                if(globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Check if user should be here
                if (globalState.sessionToken.ipn !== "ip1p" || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                window.location.href = rp;
            }

        })();
    }, []);

    useEffect(() => {        
        focusRef?.current.focus();
    }, []);

    return (
        <>
            {isAuthenticated && 
                <div>
                    <div tabIndex={-1} ref={focusRef}></div>
                    <h1>Verify your second credential</h1>

                    <TravelDocumentForm
                        instructions={<p>Enter your information, as it appears on your Foreign passport with valid visa or entry stamp.</p>}
                        product={<Product heading="Foreign passport with valid visa or entry stamp" media={require('../../assets/images/Australian Visa.png')} action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />}
                        backNavigateTo="/ip-uplift/select-other-document"
                        nextNavigateTo="UNKNOWN"
                        typeCode="VI"
                        identifierType="Passport Number"
                        action={verifyVisa}
                        documentLengthValidatorLength={14}
                        nameLengthValidatorLength={49}
                        namePatterValidatorPattern={/[^a-zA-Z0-9'’ -]/}
                        namePatternValidatorPatternHint="Only the following special characters and symbols are allowed ' -"
                        givenNameMandatory={true}
                        primaryCredentialLabel="Foreign passport with valid visa or entry stamp"
                        allowSingleName={true}
                    />
                </div>
            }
        </>)
};

export default VisaForm;