import React from "react";
import * as Constants from '../constants';

export default class RelyingPartyBuilder {
    defaultReturn:string;
    relyingParty:string | undefined;
    returnCode:number | undefined;
    acrValue:string | undefined;

    constructor() {
        this.defaultReturn = window.location.origin;
        return this;
    }

    withRelyingParty(relyingParty:string) {
        const sessionRP = sessionStorage.getItem('QDI-RP')!;
        if (sessionRP === Constants.INVALID_RELYING_PARTY_REDIRECT) {
            this.relyingParty = document.location.protocol + "//" + Constants.TMP_DLA_URI + Constants.INVALID_RELYING_PARTY_REDIRECT;
        } else {
            this.relyingParty = relyingParty;
        }
        console.log("the refer we will use is ", this.relyingParty);
        return this;
    }

    withReturnCode(returnCode:number) {
        this.returnCode = returnCode;
        return this;
    }

    withAcValue(acrValue:string) {
        this.acrValue = acrValue;
        return this;
    }

    build() {
        let uri = new URL((this.relyingParty) ? this.relyingParty : this.defaultReturn);
        if (this.returnCode)
            uri.searchParams.append("returnCode", `${this.returnCode}`);
        if (this.acrValue)
            uri.searchParams.append("acr", `urn:id.gov.au:tdif:acr:${this.acrValue}`);

        if (Constants.DEBUG) {
            console.log("%c[RelyingPartyBuilder] URI: %o", 'color: #f96;', uri);
        }

        return uri.href;
    }
}