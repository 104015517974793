import * as Constants from "../constants";
import { v4 as uuidv4 } from 'uuid';

/**
 * De-protocoled API JSON response value object. 
 * 
 * @param body the JSON object of the API response
 * @param code the http status code returned by the API
 */
export class APIJSONResponse {
    body : any;
    code: number;

    constructor(body:any, code:number) {
        this.body = body;
        this.code = code;
    }
}

/**
 * Generic function that makes a http protocol call against an API and returns the JSON response. Caught errors throw a standard Error.
 * 
 * @param api uri of the api sans host
 * @param method GET POST HEAD OPTIONS PUT DELETE
 * @param body json payload
 * @param accessToken a JWT that has rights to call the APIs
 * @param allowedResponses optional array of http status codes API is allowed to return as not an error
 * @throws Error if allowedResponses is not meet or on catch(error)
 * 
 * @returns an API wrapper object
 */
const Call = async (api: string, method: string, body: any, accessToken: string, allowedResponses?: Array<number>) => {
    try {
        let uri = Constants.API_URI + api;

        const response = await fetch(uri, {
            method: method,
            headers: {
                'x-correlation-id' : uuidv4(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'eip_client_id': `${Constants.EIP_CLIENT_ID}`,
                Authorization: `Bearer ${accessToken}`
            },
            body: (body) ? JSON.stringify(body, (key, value) => {
                if (value !== null && (typeof value =='string'? value.length > 0: true)) return value
              }): null
        });

        let returns = '';
        if(response.status !== 204) {
            returns = await response.json();
        }

        if (allowedResponses && !allowedResponses.includes(response.status)) {
            console.error(returns);
            throw new Error ("API call " + api + " failed with code " + response.status);
        }
        
        return new APIJSONResponse(returns, response.status);
    } catch(error) {
        console.error(error);

        throw new Error("API call " + api + " failed with error.");
    }
}

const CallAPIGateway = async (api: string, method: string, body: any, accessToken: string) => {
    try {
        let uri = Constants.APIGATEWAY_URI + api;

        const response = await fetch(uri, {
            method: method,
            headers: {
                "X-Request-Id": uuidv4(),
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: (body) ? JSON.stringify(body, (key, value) => {
                if (value !== null && (typeof value =='string'? value.length > 0: true)) return value
              }): null
        });

        let returns = '';
        if(response.status !== 204) {
            returns = await response.json();
        }
        
        return new APIJSONResponse(returns, response.status);
        
    } catch (error) {
        console.log(error);
        throw new Error("APIGateway call " + api+ " failed with error");
    }
}

export const VERBS = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE'
};

export {Call, CallAPIGateway}