import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from '../../../constants';

import LabelInput from "../../swe/base/label-input";
import LabelSelect from '../../swe/base/label-select';
import LabelDateInput from "../../swe/base/label-date-input";
import PrimaryButton from "../../swe/primary-button";
import SecondaryButton from "../../swe/secondary-button";
import DocumentVerificationFailedAlert from "../alerts/document-verification-failed-alert";
import DocumentAlreadyVerifiedAlert from "../alerts/document-already-verified-alert";
import {
  MandatoryValidator, LengthRangeValidator, MaximumLengthValidator, PatternValidator,
  NonSpaceInputValidator, NotFutureDateValidator, NumbersNotAllowedValidator
} from "../../../utils/validation/validator";
import { XBody, DocumentAlreadyVerifiedError, DocumentVerificationError, AssociateQDIUserAccountBuilder } from "../../../services/x-api";
import FormTypeProps from "./Form-type";
import useGlobalState from "../../../hooks/useGlobalState";
import { LabelDateInputValidator } from "../../../utils/validation/LabelDateInputValidator";

export const LicenceDocumentForm = (props: FormTypeProps) => {
  const navigate = useNavigate();
  const { saveGlobalState } = useGlobalState();
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  // validators
  let mandatoryValidator = new MandatoryValidator();
  let lengthRangeValidator89 = new LengthRangeValidator(8, 9, true);
  let maximumLengthValidator100 = new MaximumLengthValidator(100);
  let patternValidatorForGivenName = new PatternValidator(/[^a-zA-Z0-9 '’\-=.]/, "The only special characters and symbols allowed are ' - = .");
  let patternValidatorForName = new PatternValidator(/[^a-zA-Z0-9 '’\-=.]/, "The only special characters and symbols allowed are ' - = .");
  let allSpacesValidator = new NonSpaceInputValidator();
  let futureDateValidator = new NotFutureDateValidator();
  let dobValidator = new LabelDateInputValidator();
  let numbersNotAllowedValidator = new NumbersNotAllowedValidator();

  const [documentLicenceNumber, setDocumentLicenceNumber] = useState('');
  const [documentLicenceNumberValid, setDocumentLicenceNumberValid] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [middleName, setMiddleName] = useState('');
  const [middleNameValid, setMiddleNameValid] = useState(true);
  const [lastName, setLastName] = useState('');
  const [lastNameValid, setLastNameValid] = useState(true);

  const [dob, setDob] = useState('');
  const [dobValid, setDobValid] = useState(true);

  const [issuingAuthority, setIssuingAuthority] = useState('QLD');
  const [issuingAuthorityValid, setIssuingAuthorityValid] = useState(true);

  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const validateIsContinueDisabled = () => {
    let areAllFieldsValid = (documentLicenceNumberValid && firstNameValid && middleNameValid && lastNameValid && dobValid);
    let areAllMandatoryFieldsPopulated = (documentLicenceNumber.length > 0 && lastName.length > 0 && dob.length > 0);
    return (!areAllFieldsValid || !areAllMandatoryFieldsPopulated);
  }

  function navigateBackTo() {
    navigate(props.backNavigateTo, { replace: true });
  }

  const [verificationFailed, setVerificationFailed] = useState(false);
  const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
  const [counter, setCounter] = useState(0);
  const alertRef = useRef(null);

  const verifyLicenceDocument = async () => {
    if (counter > 4) {
      navigate("/ip-uplift/too-many-attempts", { replace: true });
    }
    setAlertVisibility(false, false);

    //const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
    const token = await getAccessTokenSilently();
    const idToken = await getIdTokenClaims();
    if (Constants.DEBUG) { console.log(token, idToken); }
    if (typeof documentLicenceNumber === "string") { documentLicenceNumber.trim(); }

    let builder = new AssociateQDIUserAccountBuilder()
      .withUserId(idToken!.sub)
      .withBirthdate(dob)
      .withFamilyName(lastName.trim().replace(/’/g, "'"))
      .withGivenName(firstName.trim().replace(/’/g, "'"))
      .withMiddleName(middleName.trim().replace(/’/g, "'"))
      .withIssuingAuthority(issuingAuthority)
      .withVerifiedDocTypeCode(props.typeCode)
      .withVerifiedDocIdentifier(props.identifierType, documentLicenceNumber);
    let envelope = new XBody(builder.build());

    try {
      await props.action(envelope, token);
      setAlertVisibility(false, false);
      setCounter(0);
      navigate(props.nextNavigateTo, { replace: true });
    }
    catch (error) {
      setCounter(counter + 1);
      if (error instanceof DocumentVerificationError) {
        processDocumentVerificationError(error); // IDP-4790
        setAlertVisibility(true, false);
      } else if (error instanceof DocumentAlreadyVerifiedError) {
        setAlertVisibility(false, true);
      }
      else {
        throw error;
      }
    }
  };

  /**
   * IDP-4819
   * Takes the message returned from the service and redirects user
   * to appropridate page OR sets the alert message to be displated
   * on the current form
   */
  function processDocumentVerificationError(error: DocumentVerificationError) {
    if (error.failureCode === "D") {
      if (error.failureMessage === "No Eligible Customer") {
        navigate("/ip-uplift/verify-your-identity/no-eligible-customer", { replace: true }); //IDP-4824

      } else if (error.failureMessage === "Not Valid for EOI" || error.failureMessage === "No Eligible Product") {
        // set flag to indicate an error to be displayed on verify-your-identity, IDP-4825
        saveGlobalState({ verificationFailed: true });
        navigate("/ip-uplift/verify-your-identity", { replace: true });
      }
    }
  }

  function setAlertVisibility(verifyFailed: boolean, docAlreadyVerified: boolean) {
    setVerificationFailed(verifyFailed);
    setDocumentAlreadyVerified(docAlreadyVerified);
  }

  useEffect(() => {
    if (verificationFailed || documentAlreadyVerified) {
      alertRef?.current?.focus();
    }
  }, [verificationFailed, documentAlreadyVerified]);

  useEffect(() => {
    const isInvalid = validateIsContinueDisabled();
    setIsContinueDisabled(isInvalid);

    if (counter > 4) {
      navigate("/ip-uplift/too-many-attempts", { replace: true });
    }
  }, [counter, documentLicenceNumberValid, firstNameValid, middleNameValid, lastNameValid, dobValid, documentLicenceNumber, lastName, dob]);

  return (
    <>
      <div tabIndex={-1} ref={alertRef}>
        {verificationFailed ? <DocumentVerificationFailedAlert /> : null}
        {documentAlreadyVerified ? <DocumentAlreadyVerifiedAlert /> : null}
      </div>

      {props.instructions}

      <section className="row cards-identity qg-cards cards_equal-height">
        {props.product}
      </section>

      <form className="qg-forms-v2">
        <ol className="questions">
          <li>
            <LabelInput id="driver-licence-number"
              label={props.id + " / Customer Reference Number"}
              inputValue={documentLicenceNumber}
              isInputValid={documentLicenceNumberValid}
              setInputValue={setDocumentLicenceNumber}
              setInputValid={setDocumentLicenceNumberValid}
              minLength={8}
              maxLength={9}
              mandatory={true}
              persistentHint={<a target="_blank" className="tmr-link" href="https://www.qld.gov.au/transport/crn" rel="noopener noreferrer">Where is it?</a>}
              hintPosition="below"
              validators={[mandatoryValidator, lengthRangeValidator89, allSpacesValidator]}
              mode="number"
            />
          </li>

          <li>
            <LabelInput id="first-name"
              label="First name"
              persistentHint="First name must be entered if it appears on your document."
              inputValue={firstName}
              isInputValid={firstNameValid}
              setInputValue={setFirstName}
              setInputValid={setFirstNameValid}
              mandatory={false}
              validators={[maximumLengthValidator100, patternValidatorForName, allSpacesValidator, numbersNotAllowedValidator]}
            />
          </li>

          <li>
            <LabelInput id="middle-name"
              label="Middle name/s"
              inputValue={middleName}
              isInputValid={middleNameValid}
              setInputValue={setMiddleName}
              setInputValid={setMiddleNameValid}
              mandatory={false}
              persistentHint="Middle name/s must be entered if they appear on your document. Separate middle names with a space."
              validators={[maximumLengthValidator100, patternValidatorForGivenName, allSpacesValidator, numbersNotAllowedValidator]}
            />
          </li>

          <li>
            <LabelInput id="last-name"
              label="Last name"
              inputValue={lastName}
              isInputValid={lastNameValid}
              setInputValue={setLastName}
              setInputValid={setLastNameValid}
              mandatory={true}
              validators={[mandatoryValidator, maximumLengthValidator100, patternValidatorForName, allSpacesValidator, numbersNotAllowedValidator]}
            />
          </li>

          <li>
            <LabelDateInput
              id="dob"
              label="Date of birth"
              inputValue={dob}
              isInputValid={dobValid}
              setInputValue={setDob}
              setInputValid={setDobValid}
              validators={[mandatoryValidator, dobValidator, futureDateValidator]}
            />
          </li>

          <li>
            <LabelSelect id="issuing-authority"
              label="Issuing authority"
              inputValue={issuingAuthority}
              isInputValid={issuingAuthorityValid}
              options={["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]}
              mandatory={true}
              persistentHint="Verification currently limited to QLD Photo Identification holders"
              disabled={true}
              setInputValue={setIssuingAuthority}
              setInputValid={setIssuingAuthorityValid}
            />
          </li>

          <li className="footer">
            <ul className="actions">
              <PrimaryButton id="submitButton" heading="Continue" action={verifyLicenceDocument} disabled={isContinueDisabled} testId="btn-continue" />
              <SecondaryButton id="backButton" heading="Back" action={navigateBackTo} testId="btn-back" />
            </ul>
          </li>
        </ol>
      </form>
    </>
  );
};

export default LicenceDocumentForm;