import React from 'react';

export default interface Validator {
    validate: (value:any, setHint:(params:string)=>any, label:string) => boolean;
}

function doOutcome(isValid: boolean, setHint: (params: string) => any, hint: string) {
    if (!isValid) {
        setHint(hint);
        return false
    }
    return true
}

export function executeValidators(value:string, validators:Validator[] | undefined, setHint: (params:any)=>any, setAlternateHint: (params:any)=>any, hint:string, setInputValid: (params:boolean)=>any) {
    if (validators) {
        validators.every(v => {
            let isValid = v.validate(value, (setAlternateHint)? setAlternateHint: setHint, hint);
            setInputValid(isValid);
            return isValid;
        })
    }
    return true;
}

export class MandatoryValidator implements Validator {
    message: string;

    constructor(message?: string, displayLabel: boolean = true) {
        this.message = message || "is required";
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(value && value.length > 0, setHint, `${label} ${this.message.split('###').join(label)}`);
    }
}

export class DeactivatableMandatoryValidator implements Validator {
    validateField: boolean;

    constructor(validateField:boolean=false){
        this.validateField = validateField;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(this.validateField ? value && value.length > 0 : true, setHint, `${label} is required`);
    }
}

/**
 * Validates A OR B mandatory for two fields; if one field is populated the other can be empty.
 *              B empty     B value
 * A empty      A invalid   A valid
 * A value      A valid     A valid
 * 
 * 'A' is the input the user is 'in'. B is the OR input.
 */
export class EitherInputMandatoryValidator implements Validator {
    otherValue:string;
    setOtherValueValid: (params: boolean) => any;
    hintOverride?:string;

    constructor(otherValue:string, setOtherValueValid: (params: boolean) => any, hintOverrride?:string) {
        this.otherValue = otherValue;
        this.setOtherValueValid = setOtherValueValid;
        this.hintOverride = hintOverrride;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        let aCheck = (value && value.length > 0)? true: false;
        let bCheck = (this.otherValue && this.otherValue.length > 0)? true:false;

        if (aCheck && !bCheck) {
            this.setOtherValueValid(true);
        }

        if (!aCheck && !bCheck) {
            return doOutcome(false, setHint, (this.hintOverride)? this.hintOverride : label + " is required");
        }
        return true;
    }
}

export class FixedLengthValidatorUnlessOtherInput implements Validator {
    maximum:number;
    isNumber:boolean;
    otherField:string;

    constructor(maximum:number,isNumber:boolean=false,otherField:string) {
        this.maximum = maximum;
        this.isNumber = isNumber;
        this.otherField = otherField;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome((this.otherField.length > 0 && value.length === 0) || value.length == this.maximum, setHint, label + " must be "+this.maximum+ ((this.isNumber)? " digits" :" characters"));
    }
}

export class FixedLengthValidator implements Validator {
    maximum:number;
    isNumber:boolean;

    constructor(maximum:number,isNumber:boolean=false) {
        this.maximum = maximum;
        this.isNumber = isNumber;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(value.length == this.maximum, setHint, label + " must be "+this.maximum+ ((this.isNumber)? " digits" :" characters"));
    }
}

export class MaximumLengthValidator extends FixedLengthValidator {
    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(value.length <= this.maximum, setHint, label + " cannot be longer than "+this.maximum+((this.isNumber)? " numbers" :" characters"));
    }
}

export class MaximumLengthValidatorUnlessOtherInput implements Validator {
    maximum:number;
    isNumber:boolean;
    otherField:string;

    constructor(maximum:number,isNumber:boolean=false,otherField:string) {
        this.maximum = maximum;
        this.isNumber = isNumber;
        this.otherField = otherField;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome((this.otherField.length > 0 && value.length === 0) || value.length <= this.maximum, setHint, label + " must be between 1 to "+this.maximum+((this.isNumber)? " numbers" :" characters"));
    }
}

export class LengthRangeValidator implements Validator {
    minimum:number;
    maximum:number;
    isNumber:boolean;

    constructor(minimum:number, maximum:number, isNumber:boolean=false) {
        this.minimum = minimum;
        this.maximum = maximum;
        this.isNumber = isNumber;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(!((value.length < this.minimum || value.length > this.maximum)&& value.length !=0), setHint, 
             ((this.isNumber)) ? 
                label +" must be "+this.minimum+"-"+this.maximum+" numbers" :
                label +" must be "+this.minimum+" to "+this.maximum+" characters"
             );
    }
}

export class PatternValidator implements Validator {
    pattern:RegExp;
    overridehint:string;
    uselabel:boolean;

    constructor(pattern:RegExp, overridehint:string, uselabel:boolean =  false) {
        this.pattern = pattern;
        this.overridehint = overridehint;
        this.uselabel = uselabel;
    }

    validate(value: any, setHint: (params: string) => any, label: string) { 
        return doOutcome(
            !(this.pattern.test(value)), 
            setHint, 
            this.uselabel ? label + ' ' + this.overridehint : this.overridehint
        );
    }
}

export class NumbersNotAllowedValidator extends PatternValidator {
    constructor() {
        super(/\d/, "cannot have numbers", true);
    }
}

export class NumbersOnlyValidator extends PatternValidator {
    constructor() {
        super(/[^\d+]/, "Only numbers are allowed");
    }
}

export class LeadingZeroValidator implements Validator {
    validateLeadingZero: boolean;

    constructor(validate:boolean=false){
        this.validateLeadingZero = validate;
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(!(this.validateLeadingZero && value.startsWith("0")), setHint, `${label} cannot start with a zero.`)
    }
}

export class NonSpaceInputValidator implements Validator {
    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome((value.trim().length > 0 || value.length === 0), setHint, `${label} cannot be all spaces`)
    }
}

export class MultipleSpacesInBetweenWordsValidator implements Validator {
    validate(value: any, setHint: (params: string) => any, label: string) {
        return doOutcome(!(/[ ]{2}/.test(value.trim())), setHint, `${label} cannot have multiple spaces in between words`)
    }
}

export class AustralianPassportValidator implements Validator {
    validate(value: any, setHint: (params: string) => any, label: string) { 
        return doOutcome(/^[a-zA-Z]{1,2}\d{7}$/.test(value), setHint, label + " must be 1 or 2 characters followed by 7 numbers");
    }
}

export class ImmiCardNumberValidator implements Validator {
    validate(value: any, setHint: (params: string) => any, label: string) { 
        return doOutcome(/^[a-zA-Z]{3}\d{6}$/.test(value), setHint, label + " must be 3 charactors followed by 6 numbers");
    }
}

export class EmailValidator implements Validator {
    orgValue: any;
    constructor(orgValue: any) {
        this.orgValue = orgValue;
    }
    validate(value: any, setHint: (params: string) => any, label: string) { 
        if (value === this.orgValue) {
            return doOutcome(false, setHint, "Updated email address can't be the same as the existing email address")
        }
        return doOutcome(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(value), setHint, "Enter a valid email address");
    }
}


export class NotFutureDateValidator implements Validator {
    validate(value: any, setHint: (params: string) => any, label: string) { 
        const today = new Date();
        today.setHours(23, 59, 59, 998);
        let selectedDate = new Date(value);
        
        if(isNaN(+selectedDate) && value.includes('-')) { //eg: "2023-01-31" : for Flexible Date pickers
            const dateParts = value.split("-");
            selectedDate = new Date(+dateParts[0], +dateParts[1] === 0 ? 0 : +dateParts[1]-1, +dateParts[2] === 0 ? 1 : +dateParts[2]); // new Date(yyyy, mm, dd) - Month is expecting 0-11, hence -1
        }

        return doOutcome(!(selectedDate > today), setHint, label + " cannot be a future date");
    }
}

export class DateRangeValidator implements Validator {
    minyear:Date;
    maxyear:Date;

    constructor(minYearValue:number, maxYearValue:number) {
        this.minyear = new Date(minYearValue,0,1);
        this.maxyear = new Date(maxYearValue+1,0,1);
    }

    validate(value: any, setHint: (params: string) => any, label: string) {
        let selectedDate = new Date(value);
        if(selectedDate.getFullYear() > 9999) {
            return doOutcome(false, setHint, `${label} format must be dd/mm/yyyy`);
        } else {
            return doOutcome(!(this.minyear > selectedDate || this.maxyear <= selectedDate), setHint, label + " can only be between the year " + this.minyear.getFullYear() +"-"+ (this.maxyear.getFullYear()-1));
        }
    }
}

export class DateOnOrAfterDateValidator implements Validator {
    otherDate:Date;
    otherDateLabel:string;

    constructor(otherDate:Date,otherDateLabel:string) {
        this.otherDate = otherDate;
        this.otherDateLabel = otherDateLabel;
    }

    validate(value:any, setHint: (params:string) => any, label: string) {
        return doOutcome(value >= this.otherDate, setHint, label + " must be on or after the " + this.otherDateLabel);
    }
}

export class IsValidDateValidator implements Validator {
    validate(value:any, setHint: (params:string) => any, label: string) {
        let d = new Date(value);
        return doOutcome(!isNaN(d.getTime()) && value.length==10, setHint, label + " format must be dd/mm/yyyy");
    }
}

/**
 * Manages validation for groups of fields where only one renders an error.
 * 
 * @param validators
 */
export class MultipleFieldValidator implements Validator {
    validators: [Validator];

    constructor(validators: [Validator]) {
        this.validators = validators;
    }
    
    validate(value: any, setHint: (params: string) => any, label: string) {
        if (this.validators) {
            this.validators.every(v => {
                let isValid = v.validate(value, setHint, label);
                return isValid;
            })
        }
        return true;
    }
}