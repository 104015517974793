import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from "../constants"

import CriticalAlert from '../components/swe/critical-alert';
import PrimaryButton from '../components/swe/primary-button';

export const TooManyAttempts = () => {
    const { isAuthenticated } = useAuth0();
    const alertRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    const errorMsg = "Not authenticated";
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
                    throw Error(errorMsg);
                }
            }
            catch (error) {
                const rp = new RelyingPartyBuilder()
                .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
                .build();

            window.location.href = rp;
    }
        })();
    }, []);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    function cancelQDI() {
        let params = (new URL(window.location.href)).searchParams;
        window.location.href = new RelyingPartyBuilder()
                                    .withRelyingParty(Constants.TMP_DLA_URI!)
                                    .withReturnCode((params.get("returnCode"))?Number(params.get("returnCode")):6)
                                    .withAcValue("IP1:CL2")
                                    .build();
    }

    return (
        <>
            {isAuthenticated && 
                <div>
                    <div tabIndex={-1} ref={alertRef}>
                        <CriticalAlert heading="Too many attempts" message={<>We were unable to successfully verify your details. Please try again later.</>} />
                    </div>
                    
                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id='close' heading='Close' action={cancelQDI}/>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>
    )
};

export default TooManyAttempts;