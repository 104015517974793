import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from "../constants";

import CriticalAlert from "../components/swe/critical-alert";
import PrimaryButton from '../components/swe/primary-button';

export const TryAgain = () => {
    const { isAuthenticated } = useAuth0();
    const alertRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    const errorMsg = "Not authenticated";
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
                    throw Error(errorMsg);
                }
            }
            catch (error) {
                const rp = new RelyingPartyBuilder()
                    .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
                    .build();

                window.location.href = rp;
            }
        })();
    }, []);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    function cancelQDI() {
        window.location.href = new RelyingPartyBuilder()
            .withRelyingParty(Constants.TMP_DLA_URI!)
            .withReturnCode(3)
            .withAcValue("IP1:CL2")
            .build();
    }

    return (
        <>
            {isAuthenticated && 
                <div>
                    <h1>Email validation code</h1>

                    <div tabIndex={-1} ref={alertRef}>
                        <CriticalAlert heading="Too many attempts" message="We are unable to successfully verify your details. Please try again later." />
                    </div>

                    <p>When you select Close, you will have the option to Log in, or Sign Up with a different email address.</p>

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id='close' heading='Close' action={cancelQDI} />
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>
    )
};

export default TryAgain;