import React, { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import history from "../utils/history";
import { useNavigate } from "react-router-dom";
import useGlobalState from "../hooks/useGlobalState";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from "../constants";

import CriticalAlert from "../components/swe/critical-alert";
import PrimaryButton from "../components/swe/primary-button";

const NotFound = () => {
  const { globalState, saveGlobalState } = useGlobalState();
  const { isAuthenticated, user } = useAuth0();
  const alertRef = useRef(null);
  const navigate = useNavigate();

  if (Constants.DEBUG) { console.log('%c[NotFound] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;', isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState); }

  useEffect(() => {
    (async () => {
      try {
        // const accesstoken = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
        // const idToken = await getIdTokenClaims();

        if (!isAuthenticated) {
          const errorMsg = "Not authenticated";
          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
          throw Error(errorMsg);
        }
      }
      catch (error) {
        //if (!Constants.DISABLE_AUTH0_REDIRECT) {
          const rp = new RelyingPartyBuilder()
            .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
            .build();

          window.location.href = rp;
        //}
      }
    })();
  }, []);

  useEffect(() => {
    alertRef?.current?.focus();
  }, []);

  if (!globalState.relyingParty) { // set only once
    saveGlobalState({ relyingParty: document.referrer });
  }

  function doBack() {
    //history.go(-1);
    //IDP-3132
    navigate("/ip-uplift", { replace: true });
  }

  return (
    <>
      {isAuthenticated &&
        <div>
          <h1>Page not found</h1>
          <div tabIndex={-1} ref={alertRef}>
            <CriticalAlert message={<p>The page you were looking for can't be found or doesn't exist. Select Back to return to your previous page.</p>} />
          </div>
          <ol className="questions">
            <li className="footer">
              <ul className="actions">
                <PrimaryButton id="back" heading="Back" action={doBack} />
              </ul>
            </li>
          </ol>
        </div>
      }
    </>
  )
};

export default NotFound;
