export const DEBUG = process.env.react_app_DEV_DEBUG === 'true' || false;

export const TIMEOUT = process.env.react_app_timeout;
export const API_URI = process.env.react_app_api_uri;
export const APIGATEWAY_URI = process.env.react_app_apigateway_uri;

export const TRY_AGAIN_TIME = process.env.react_app_try_again_time;
export const ALLOWED_ACR = process.env.react_app_allowed_acr;
export const DEFAULT_ACR = process.env.react_app_default_acr;

export const IP_MAPPINGS = {
    'IP1:CL1': 'urn:id.gov.au:tdif:acr:ip1:cl1',
    'IP1:CL2': 'urn:id.gov.au:tdif:acr:ip1:cl2',
    'IP1P:CL1': 'urn:id.gov.au:tdif:acr:ip1p:cl1',
    'IP1P_NC:CL1': 'urn:id.gov.au:tdif:acr:ip1p_nc:cl1',
    'IP1P:CL2': 'urn:id.gov.au:tdif:acr:ip1p:cl2',
    'IP2:CL2': 'urn:id.gov.au:tdif:acr:ip2:cl2'
}

export const TMP_DLA_URI = `${sessionStorage.getItem('QDI-RP')?sessionStorage.getItem('QDI-RP'):window.location.host}`;

export const AUTH0_URI = process.env.react_app_auth0_uri;
export const AUTH0_CLIENTID = process.env.react_app_auth0_clientId;
export const AUTH0_UNAUTHORISED_PATH = `${TMP_DLA_URI?.replace(/\/$/, '')}/?returnCode=99`;

export const DISABLE_AUTH0_REDIRECT = (/^localhost$/.test(window.location.hostname) && true) || (process.env.react_app_DEV_DISABLE_AUTH0_REDIRECT === 'true') || false;

export const EIP_CLIENT_ID = process.env.react_app_eip_client_id;

export const CONTACT_US_URL = "https://www.qld.gov.au/transport/projects/digital-licence/queensland-digital-identity";
export const HELP_URL = "https://www.qld.gov.au/transport/projects/digital-licence/queensland-digital-identity#troubleshooting";
export const CONTACT_US_TEL = "tel:1800317389";
export const CONTACT_US_TEL_DISPLAY = "1800 317 389";

export const TANDC_TIMESTAMP=process.env.react_app_tandc_timestamp;

export const SERVICE_STATUS_POLLING_INTERVAL = process.env.react_app_service_status_polling_interval;
export const SERVICE_STATUS_ENDPOINT = process.env.react_app_service_status_endpoint;

export const ALLOWED_RELYING_PARTYS = process.env.react_app_allowed_rp;
export const INVALID_RELYING_PARTY_REDIRECT = process.env.react_app_invalid_rp_redirect;
export const ALLOWED_OTP_ATTEMPTS  = process.env.react_app_allowed_otp_resend_attempts;
export const OTP_RESEND_WAIT_TIME = process.env.react_app_otp_resend_wait_time;

export { };