import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from '../constants';
import Loading from "../components/Loading";

// state
import useGlobalState from "../hooks/useGlobalState";

import PrimaryButton from "../components/swe/primary-button";

const Home = () => {
  const linkContactUs = Constants.CONTACT_US_URL;
  const navigate = useNavigate();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { globalState, saveGlobalState } = useGlobalState();
  const { loginWithRedirect } = useAuth0();
  const [QDIStatus, setQDIStatus] = useState('');

  const sleep = m => new Promise(r => setTimeout(r, m))

  useEffect(()=>{
      async function checkServiceStatusSession() {
          if (sessionStorage.getItem('QDI-RP') !== Constants.INVALID_RELYING_PARTY_REDIRECT && sessionStorage.getItem("QDI-Status") !== null) {
            while(sessionStorage.getItem("QDI-Status") !== "UP" && sessionStorage.getItem("QDI-Status") !== "DOWN") {
              console.log('Waiting for QDI Service Status..');
              await sleep(1000);
            }
            console.log('QDI Service Status recieved.');
            setQDIStatus(sessionStorage.getItem('QDI-Status'));
          }
      }

      checkServiceStatusSession();
  },[]);

  useEffect(() => {
    async function logoutSilently() {
        if (Constants.DEBUG) console.log('Check for logged in users...');
        if (isAuthenticated) {
            const idToken = await getIdTokenClaims();
            if (idToken) {
                if (Constants.DEBUG) console.log('silently logging out user', idToken);
                const logoutUrl = `${process.env.react_app_auth0_uri}/v2/logout?id_token_hint=${idToken.__raw}&post_logout_redirect_uri=${window.location.origin}`;
                const iframe = document.createElement('iframe');
                iframe.src = logoutUrl;
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
                await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for the logout to complete
                document.body.removeChild(iframe);
                if (Constants.DEBUG) console.log('logged out...');
                // Optionally, perform additional cleanup or actions after logout
            }
        }
    };
    logoutSilently();
}, []);

  if (!globalState.relyingParty) { // set only once
    saveGlobalState({ relyingParty: document.referrer });
  }

  let queryParams = new URLSearchParams(window.location.search);
  let requestedAcr = queryParams.get("acr");
  let allowedACRArray = (Constants.ALLOWED_ACR) ? Constants.ALLOWED_ACR.split(',') : [];

  if (!requestedAcr) {
    saveGlobalState({ acr: Constants.DEFAULT_ACR });
  }
  else if (allowedACRArray.includes(requestedAcr)) {
    saveGlobalState({ acr: Constants.IP_MAPPINGS[requestedAcr] });
  } else if (requestedAcr) {
    if ((window.location.href === document.referrer) || !document.referrer) {
      throw new Error();
    } else {
      window.location = document.referrer + "?returnCode=12";
    }
  }

  function doAuth0SignUp() {

    if (Constants.DEBUG)  {
      console.log("User requesting signup for ", globalState.acr)
      console.log('Clear local and session storage...');
    }

    localStorage.clear();
    sessionStorage.clear();

    // store, as globalState is destroyed on jump to auth0
    window.sessionStorage.setItem("QDI-IP", requestedAcr);
    window.sessionStorage.setItem("QDI-RP", globalState.relyingParty);

    loginWithRedirect({ hint: "login", screen_hint: "signup", acr_values: globalState.acr });
  }

  return (
    <>
      {QDIStatus === '' ? <Loading /> :
        <>
          <h1>Create A Queensland Digital Identity</h1>

          <div style={{ margin: "0 auto" }}>
            <p>
              You are about to create a Queensland Digital Identity (QDI).
            </p>
            <p>
              The QDI will allow you to interact safely, securely and easily across Queensland Government, accessing services and transactions.
            </p>
            <p>
              In order to get the QDI, you will need to provide some forms of identification, which will be verified during the process.
            </p>
            <p>
              If you have any questions or concerns, please <a href={linkContactUs}>contact us</a>.
            </p>
            <ol className="questions">
              <li className="footer">
                <ul className="actions">
                  <PrimaryButton id="continue" heading="Continue" action={doAuth0SignUp} />
                </ul>
              </li>
            </ol>
          </div>
        </>
      }
    </>
  );

};

export default Home;
