import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../hooks/useGlobalState";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import * as Constants from '../constants';
import {extractIpn} from "../utils/helpers";

import Callout from "../components/swe/base/callout";
import LabelInput from "../components/swe/base/label-input";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";
import { EmailValidator } from "../utils/validation/validator"
import { sendVerificationCode } from "../services/authn-api";
import CriticalAlert from "../components/swe/critical-alert";

export const ChangeEmail = () => {
    const navigate = useNavigate();
    const { getAccessTokenSilently, getIdTokenClaims, user, isAuthenticated, logout } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();
    const focusRef = useRef(null);
    const [emailExists, setEmailExists] = useState(false);
    const rp = new RelyingPartyBuilder().withRelyingParty(Constants.TMP_DLA_URI).withReturnCode(4).build();

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }

                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();

                if (Constants.DEBUG) {
                    console.log('%cisAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                const ipn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
                if (Constants.DEBUG) { console.log('user claims  ipn ->', ipn); }
                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
                if(globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Test if authenticated user has already verified email
                if (user && user.email_verified) {
                    console.log('User already verified their email. Redirecting to uplift...');
                    navigate("/ip-uplift", { replace: true });
                }
            }
            catch (error) {
                if (Constants.DEBUG) {
                    console.log('%c%s', 'color: #c00;', error);
                }
                const rp = new RelyingPartyBuilder()
                    .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
                    .build();
                window.location.href = rp;
            }
        })();
    }, []);

    useEffect(()=>{
        focusRef?.current?.focus();
    }, []);
 
    if(globalState?.email){
        sessionStorage.setItem('userEmail', globalState?.email);
    } else {
        saveGlobalState({ email: sessionStorage.getItem('userEmail') });
    } 

    const [newEmail, setNewEmail] = useState('')
    const [newEmailValid, setNewEmailValid] = useState(true);

    const email = globalState?.email === null ? sessionStorage.getItem('userEmail') : globalState?.email;

    let emailValidator = new EmailValidator(email);


    function cancelEmailChange() {
        saveGlobalState({ cancelEmailChange: true });
        navigate('/ip-uplift/enter-otp', { replace: true })
    }

    function sendNewEmail() {
        setEmailExists(false);
        sendEmail();
    }

    const sendEmail = async () => {
        const accesstoken = await getAccessTokenSilently({ ignoreCache: true });
        if (Constants.DEBUG) {
            console.log('%caccessToken: %o', 'color: #6f3;', accesstoken);
        }

        let checkEmail = user.email === newEmail ? "false" : "true";

        let response = await sendVerificationCode(accesstoken, newEmail, checkEmail);

        console.log(response);
        if(response.code === 200){
            saveGlobalState({ emailchanged: true, email: newEmail });
            sessionStorage.setItem('userEmail', newEmail);
            navigate('/ip-uplift/email-changed', { replace: true });
        } else {
            setEmailExists(true);
        }
    }

    return (
        <>
            {isAuthenticated && 
                <div>
                    <div tabIndex={-1} ref={focusRef}></div>
                    <h1>Change your email address</h1>

                    <p>Existing email address:</p>

                    <Callout message={email} showIcon={false} />

                    {emailExists &&
                      <CriticalAlert 
                        heading="" 
                        message={<p style={{marginTop: 8}}>The email you have entered is incorrect. <br/> Please check and try again, or <a href={rp}>log in</a> if you have already created a Queensland Digital Identity with this address.</p>}
                      />
                    }

                    <form className="qg-forms-v2">
                        <ol className="questions">
                            <li>
                                <LabelInput
                                    id="emailAddress"
                                    label="Updated email address"
                                    inputValue={newEmail.toLowerCase()}
                                    isInputValid={newEmailValid}
                                    setInputValue={setNewEmail}
                                    setInputValid={setNewEmailValid}
                                    validators={[emailValidator]}
                                />
                            </li>
                        </ol>
                    </form>

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id="submitButton" heading="Continue" action={sendNewEmail} disabled={!newEmail || !newEmailValid} />
                                    <SecondaryButton id="backButton" heading="Back" action={cancelEmailChange} />
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>
    );
}

export default ChangeEmail;