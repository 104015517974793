import { useAuth0 } from '@auth0/auth0-react';
import * as Constants from "../constants";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Callback = () => {
    const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();

    if (Constants.DEBUG) { console.log("%c[Callback] isAuthenticated: %o; user: %o", 'color: #f96;', isAuthenticated, user); }

    useEffect(() => {
        if (!isAuthenticated) {
            window.location.href = new RelyingPartyBuilder()
                .withRelyingParty(Constants.TMP_DLA_URI!).build();
        } else {
            if (user!['http://oauth.tmr.qld.gov.au'].ipn === 'ip2') {
                // user has completed uplift
                window.location.href = new RelyingPartyBuilder()
                    .withRelyingParty(Constants.TMP_DLA_URI!)
                    .withAcValue("IP2:CL2")
                    .build();
            } else {
                // user needs to complete uplift
                navigate("/ip-uplift", { replace: true });
            }
        }
    })

    // function returnToDLA() {
    //     function returnToDLA() {
            // getIdTokenClaims()
            // .then(claims => {
            //     if (Constants.DEBUG) {
            //         console.group('[getIdTokenClaims:after]');
            //         console.log("%cclaims:%o\nDLA_URI: %o", 'color: #f96;', claims, Constants.TMP_DLA_URI);
            //         console.groupEnd();
            //     }
            //     window.location.href = new RelyingPartyBuilder()
            //         .withRelyingParty(Constants.TMP_DLA_URI!)
            //         .withAcValue("IP2:CL2")
            //         .build();
            // }).catch(err => {
            //     throw Error(err);
            // })
    // }

    return (<>
        {false &&
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Identity set up complete</h1>

                <div className="alert alert-danger" role="alert">

                    <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                    You have successfully set up your Queensland Digital Identity!

                    <p>Next time you are asked for your Queensland Digital Identity, select Login, and use the email address and password you set earlier.</p>

                </div>

                <ol className="questions">
                    <li className="footer">
                        <ul className="actions">
                            {/* <PrimaryButton id="continue" heading="Continue" action={returnToDLA} /> */}
                        </ul>
                    </li>
                </ol>

            </div>
        }
    </>)
};

export default Callback;
