import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from "../constants"

import CriticalAlert from '../components/swe/critical-alert';
import PrimaryButton from '../components/swe/primary-button';
import {extractCl} from "../utils/helpers";

export const TooManyResendAttempts = () => {
    const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
    const alertRef = useRef(null);
    let clValue:string|null;

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated) {
                    const errorMsg = "Not authenticated";
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', errorMsg); }
                    throw Error(errorMsg);
                }

                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                clValue = extractCl(claims && claims['http://oauth.tmr.qld.gov.au'].acr);

            }
            catch (error) {
                const rp = new RelyingPartyBuilder()
                .withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH)
                .build();

            window.location.href = rp;
    }
        })();
    }, []);

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    function cancelQDI() {
        let params = (new URL(window.location.href)).searchParams;
        window.location.href = new RelyingPartyBuilder()
                                    .withRelyingParty(Constants.TMP_DLA_URI!)
                                    .withReturnCode(8)
                                    .withAcValue(`IP1:${clValue}`)
                                    .build();
    }

    return (
        <>
            {isAuthenticated && 
                <div>
                    <div>
                    <h1>Enter your email verification code</h1>
                        <CriticalAlert 
                            heading="Too many resend attempts" 
                            message={<>
                                        <p>We were unable to successfully verify your email address.</p>
                                        <p>Please <a href={Constants.HELP_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>
                                    </>} 
                        />
                    </div>

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <PrimaryButton id='close' heading='Close' action={cancelQDI}/>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            }
        </>
    )
};

export default TooManyResendAttempts;