import { useAuth0 } from '@auth0/auth0-react';
import * as Constants from "../constants";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/swe/primary-button";
import useGlobalState from "../hooks/useGlobalState";
import { extractIpn } from "../utils/helpers";

export const Success = () => {
    const { isAuthenticated, user } = useAuth0();
    const { globalState } = useGlobalState();
    const navigate = useNavigate();

    if (Constants.DEBUG) { console.log("%c[Success] isAuthenticated: %o; user: %o", 'color: #f96;', isAuthenticated, user); }

    useEffect(() => {
        (async () => {
            if (!isAuthenticated) {
                window.location.href = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
            } else {

                let mfaAvailable = globalState.userCompletedMFA;
                if (typeof mfaAvailable === 'undefined' || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift");}
                    navigate("/ip-uplift", { replace: true });
                }

                console.log("Uplift completed page.");
            }
        })();
    })

    function returnToDLA() {
        if (Constants.DEBUG) { console.log("User successfully complete uplift. Returning to RP"); }
        window.location.href = new RelyingPartyBuilder()
            .withRelyingParty(Constants.TMP_DLA_URI)
            .withAcValue("IP2:CL2")
            .build();

    }

    return (<>
        {isAuthenticated &&
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Identity set up complete</h1>

                <div className="alert alert-success" role="alert">

                    <h2><i className="fa fa-check-circle" style={{ float: "left" }}></i></h2>
                    You have successfully set up your Queensland Digital Identity!

                    <p>Next time you are asked for your Queensland Digital Identity, select Login, and use the email address and password you set earlier.</p>

                </div>

                <ol className="questions">
                    <li className="footer">
                        <ul className="actions">
                            <PrimaryButton id="continue" heading="Continue" action={returnToDLA} />
                        </ul>
                    </li>
                </ol>

            </div>
        }
    </>)
};


export default Success;
