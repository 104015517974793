import CriticalAlert from "../components/swe/critical-alert";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import * as Constants from "../constants";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Displays a session timeout page
 * 
 * If the user has come from a recognised RP then link will be displayed back to RP
 * otherwise just session timeout message is displayed
 * 
 * @returns the page
 */
export default function SessionTimeout() {

    const { isAuthenticated, logout, getIdTokenClaims } = useAuth0();
    const alertRef = useRef(null);
    const navigate = useNavigate();

    const referringUrl = sessionStorage.getItem('QDI-RP');
    if (Constants.DEBUG) { console.log('session time out page, referers url is ', referringUrl); }

    const rp = new RelyingPartyBuilder().withRelyingParty(referringUrl!).withReturnCode(9).build();

    const handleSilentLogout = async () => {
        if (Constants.DEBUG) console.log('handle silent logout...');
        if (isAuthenticated) {
            if (Constants.DEBUG) console.log('logging user out...');
            const idToken = await getIdTokenClaims();
            if (idToken) {
                const logoutUrl = `${process.env.react_app_auth0_uri}/v2/logout?id_token_hint=${idToken.__raw}&post_logout_redirect_uri=${window.location.origin}`;
                localStorage.clear();
                sessionStorage.clear();
                const iframe = document.createElement('iframe');
                iframe.src = logoutUrl;
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
                await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for the logout to complete
                document.body.removeChild(iframe);
                if (Constants.DEBUG) console.log('Logged out user. Clearing local and session storage...');
            }
        }
    };

    useEffect(() => {
        alertRef?.current?.focus();
        handleSilentLogout();
    }, [])

    return (<>
        <div>
            <h1>Your session has expired due to inactivity</h1>

            {referringUrl &&
                <div tabIndex={-1} ref={alertRef}>
                    <CriticalAlert message={<p>You will need to <a href={rp}>Login or Sign up</a> to continue setting up your Queensland Digital Identity.</p>} />
                </div>
            }

            <div style={{ marginBottom: '150px' }} />
        </div>
    </>);
}