import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getQDIServiceStatus } from "../services/x-api";
import * as Constants from "../constants";
import { useLocation } from "react-router-dom";

const RelyingPartyAndServiceStatusCheck = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        async function checkServiceStatus() {
            try {
                sessionStorage.setItem("QDI-Status", "RETRIEVING");
                const response = await getQDIServiceStatus();
                console.log("QDI Service Status: ", response);
                if(response.service_status === 'DOWN') {
                    sessionStorage.setItem("QDI-Status", "DOWN");
                    navigate("/service-unavailable?message=" + response.status_message, { replace: true });
                } else {
                    sessionStorage.setItem("QDI-Status", "UP");
                }
            } catch (error) {
                console.log(error);
                sessionStorage.setItem("QDI-Status", "UP");
            }
        }

        //check for invalid RP redirection
        if (sessionStorage.getItem('QDI-RP') === Constants.INVALID_RELYING_PARTY_REDIRECT) {
            console.log('redirect to invalid referrers url');
            navigate(Constants.INVALID_RELYING_PARTY_REDIRECT, { replace: true });
        } else {
            // Check service status on login and signup pages if the refferer is valid
            if(location.pathname.includes("/login") || location.pathname.includes("/signup") || location.pathname === "/"){
                checkServiceStatus();
            }
        }

        return;
    },[]);

    return null;
}

export default RelyingPartyAndServiceStatusCheck;