import * as Constants from '../constants';

/**
  * Convert yyyy-mm-dd or yyyy-mm- or yyyy-- to respectively yyyy-mm-dd or yyyy-mm or yyyy format.
  * 
  * @param ddmmyyyy 
  */
export const formatDateString = (yyyymmdd: string): string => {
  //return ddmmyyyy.split("/").reverse().join("-");
  let dateParts = yyyymmdd.split('-');
  let year = +dateParts[0];
  let month = +dateParts[1];
  let day = +dateParts[2];

  if(year > 0 && month > 0 && day === 0) {
    return dateParts[0] + '-' + dateParts[1];
  } else if (year > 0 && month === 0 && day === 0) {
    return dateParts[0];
  }

  return yyyymmdd;
}

export const extractIpn = (str: string) => {
  if (!str) return null;
  const parts = str.split(':');
  const ipn = parts[4];
  return ipn;
};

export const extractCl = (str: string) => {
  if (!str) return null;
  const parts = str.split(':');
  const ipn = parts[5];
  return ipn;
};

export const updateLocalStorageSessionTokenIpn = (ipn: string) => {
  let session_token = localStorage.getItem('session_token');
  if(session_token){
      let sessionTokenJSON = JSON.parse(session_token!);
      sessionTokenJSON.ipn = ipn;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
  }
}

export const updateLocalStorageSessionTokenIpnAndStatus = (ipn: string, ipnStatus: string) => {
  let session_token = localStorage.getItem('session_token');
  if(session_token){
      let sessionTokenJSON = JSON.parse(session_token!);
      sessionTokenJSON.ipn = ipn;
      sessionTokenJSON.ipn_status = ipnStatus;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
  }
}