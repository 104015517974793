import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants";
import useGlobalState from "../hooks/useGlobalState";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import { extractIpn } from "../utils/helpers";

import "../assets/css/qdi-portal.css";
import Product from '../components/qdi/product';
import SecondaryButton from "../components/swe/secondary-button";
import CriticalAlert from '../components/swe/critical-alert';

/**
 * Displays the VerifyYourIdentity page and navigates to the user selected TMR product page
 * @returns the page
 */
export function VerifyYourIdentity() {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const focusRef = useRef(null);
    var verificationFailed = globalState.verificationFailed;

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }

                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const ipn = extractIpn(claims['http://oauth.tmr.qld.gov.au'].acr);
                const acr = user["http://oauth.tmr.qld.gov.au"].acr;

                if (Constants.DEBUG) {
                    console.log('user claims  ipn ->', ipn);
                    console.log('user object acr ->', acr);
                    console.log('%c[VerifyYourIdentity] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Check if user should be here 
                // let mfaAvailable = user["http://oauth.tmr.qld.gov.au"].mfa_available;
                //if (Constants.DEBUG) { console.log("mfa_available is ", mfaAvailable); }
                if (Constants.DEBUG) { console.log("userCompletedMFA is ", globalState.userCompletedMFA); }
                if (typeof globalState.userCompletedMFA === 'undefined' || globalState.sessionToken.ipn !== "ip1" || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }

                // reset the licence verification flag to false so as only display error alert, (IDP-4825), once
                saveGlobalState({ verificationFailed: false });

            }
            catch (error) {
                if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
                const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                window.location.href = rp;
            }

        })();
    }, []);

    useEffect(() => {
        focusRef?.current?.focus();
    }, []);

    const [cancelled, setCancelled] = useState(false);

    async function cancelClicked(event) {
        if (cancelled) {
            window.location.href = new RelyingPartyBuilder()
                .withRelyingParty(Constants.TMP_DLA_URI)
                .withReturnCode(5)
                .withAcValue("IP1:CL2")
                .build();
        }
        setCancelled(true);
    }

    const displayDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/drivers-licence-form", { replace: true });
    }
    const displayMarineLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/marine-licence-form", { replace: true });
    }
    const displayPhotoIdentificationForm = () => {
        navigate("/ip-uplift/verify-your-identity/photo-id-form", { replace: true });
    }

    return (
        <>
            {isAuthenticated &&
                <div>
                    <h1>Verify your first credential</h1>
                    <div tabIndex={-1} ref={focusRef}>
                        {verificationFailed ? <CriticalAlert heading="Document is invalid" message={<p>The document you are attempting to verify is invalid. Select a <a href={Constants.CONTACT_US_URL + '#component_382339'}>valid document</a> from the list below.<br />If you believe this to be incorrect, <a href={Constants.HELP_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>} /> : null}
                    </div>
                    <div>
                        <p>
                            So we can confirm you are who you say you are, we will verify your identity using some forms of identification.
                        </p>
                        <p>
                            You will only need to complete this process one time. Once you have successfully verified your identity, you will not have to do so again.
                        </p>
                        <p>
                            <strong>Please select one credential that you have from the list below:</strong>
                        </p>

                        <section className="row cards-identity qg-cards">
                            <Product
                                heading="Queensland Driver Licence"
                                media={require('../assets/images/driverlicence.jpg')} a
                                ltText="QLD Drivers Licence" action={displayDriversLicenceForm}
                            />
                            <Product
                                heading="Queensland Photo Identification Card or Adult Proof of Age Card"
                                media={require('../assets/images/photoidentification.png')}
                                altText="QLD Photo Identification Card or Adult Proof of Age Card"
                                action={displayPhotoIdentificationForm}
                            />
                            <Product
                                heading="Queensland Recreational Marine Licence"
                                media={require('../assets/images/marine.jpg')}
                                alt="QLD Marine Licence"
                                action={displayMarineLicenceForm}
                            />
                        </section>

                        {cancelled &&
                            <div id='cancelAlert' className="alert alert-warning" role="alert">
                                <h2 className="alert-heading">
                                    <span className="fa fa-exclamation-triangle"></span>Sure you want to cancel?
                                </h2>
                                <div>
                                    <p>If you cancel, you will be returned to the service page, and your identity will not be verified.
                                    </p>
                                    <p>
                                        You will be able to verify your identity later by logging in using your email and password, and continuing this process.
                                    </p>
                                    <p>
                                        Select Cancel if you wish to cancel this process.
                                    </p>
                                </div>
                            </div>
                        }

                        <ol className="questions">
                            <li className="footer">
                                <ul className="actions">
                                    <SecondaryButton id="cancelButton" heading="Cancel" action={cancelClicked} />
                                </ul>
                            </li>
                        </ol>



                    </div>
                </div>
            }
        </>)
}

export default VerifyYourIdentity;
