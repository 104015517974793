import React, { Component } from "react";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import CriticalAlert from "../swe/critical-alert";
import * as Constants from "../../constants";

interface State {
    error: any; // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
}

class ErrorBoundary extends Component<State> {
    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        const errorType = event.reason.error;
        // if (errorType === 'login_required') {
        //     console.log(event);
        // }
        this.setState({
            error: event.reason
        });
    }

    public state: State = {
        error: null
    };

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    public componentDidCatch(error: Error, errorInfo: any) {
        console.error("Uncaught error:", error, errorInfo);
    }

    componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    }

    cancelQDI() {
        window.location.href = new RelyingPartyBuilder()
            .withRelyingParty(Constants.TMP_DLA_URI!)
            .withReturnCode(10)
            .withAcValue(window.sessionStorage.getItem("QDI-IPE")!)
            .build();
    }

    public render() {
        if (this.state.error) {
            return (
                <>
                    <h1>Queensland Digital Identity</h1>

                    <CriticalAlert heading="An unexpected error has occurred" message="Please try again later." />

                    <div className="my-3">
                        <ol className="questions">
                            <li>
                                <ul className='actions'>
                                    <li>
                                        <button type="button" className="qg-btn btn-primary" onClick={this.cancelQDI.bind(this)}>Close</button>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </>
            )
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;