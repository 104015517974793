import { useState, useRef } from "react";
import InputTypeProps from "./input-type";
import Label from "./Label";
import { executeValidators } from "../../../utils/validation/validator";

interface LabelDateInputTypeProps extends InputTypeProps {
    labelClassName?: string,
    flexibleDate?: boolean
}

/**
 * Date input using 3 seperate inputs for Day, Month and Year.
 * Day input: Accepts values from 1 - 31. If enter a number greater than 31, number will be automatically set to 31.
 *            If enter 00 or less than 0 value (minus value), number will be set to 01.
 *            Pad 0 to the front when enter the first number. If the first entered 
 *            number is greater than 3, then focus to the Month input as there is no possible day start with a 
 *            number greater than 3 other than the number itself. 
 *            Eg: If user enter number 4, Day input will be filled with 04 as it is the only possible day starts 
 *                with number 4.
 * Month Input: Accepts values from 1 - 12. If enter a number greater than 12, number will be automatically set to 12.
 *            If enter 00 or less than 0 value (minus value), number will be set to 01.
 *            Pad 0 to the front when enter the first number. If the first entered 
 *            number is greater than 1, then focus to the Year input as there is no possible day start with a 
 *            number greater than 1 other than the number itself. 
 *            Eg: If user enter number 2, Day input will be filled with 02 as it is the only possible day starts 
 *                with number 2.
 * Year Input: Accepts values from 0000 - 9999. Entered number will be padded with leading zeros upto 4 charactors.
 *             Eg: If entered 1, output will be 0001. If enter 19 output will be 0019.
 * flexibleDate: If this flag set to true, error input-alert class will be only applied to Year input when there is
 *               a validation failure. When setting this flag to true, FlexibleDateValidator must be used.
 * 
 * @returns yyyy-MM-dd format date string to props.inputValue (including incompleted dates such as '-01-31')
 */
export const LabelDateInput= (props : LabelDateInputTypeProps) => {
    const [hint,setHint] = useState('');
    const monthRef = useRef(null);
    const yearRef = useRef(null);

    const validateDay = (event:any) => {
        let date = props.inputValue;
        let dateParts = props.inputValue.split('-');
        let enteredDay = event.target.value > 31 ? 31 : (event.target.value < 0 ? '01' : event.target.value);

        if(enteredDay.length >= 2 || +enteredDay > 3) {
            monthRef?.current?.focus();
        }

        if(enteredDay.length >= 2 && +enteredDay === 0) {
            enteredDay = '01';
        }

        enteredDay = enteredDay.length === 1 && !enteredDay.match(/^0/) ? 
                        enteredDay.padStart(2,'0') : 
                        enteredDay.length === 3 ? enteredDay.replace(/^0/, '') : enteredDay;

        date = (dateParts[0] ? dateParts[0] : '') + '-' +  (dateParts[1] ? dateParts[1] : '') +'-'+ enteredDay;
        date = date !== "--" ? date : '';
        
        executeValidators(date, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid);
        props.setInputValue(date);
    }

    const validateMonth = (event:any) => {
        let date = props.inputValue;
        let dateParts = props.inputValue.split('-');
        let enteredMonth = event.target.value > 12 ? 12 : (event.target.value < 0 ? '01' : event.target.value);

        if(enteredMonth.length >= 2 || +enteredMonth > 1) {
            yearRef?.current?.focus();
        }

        if(enteredMonth.length >= 2 && +enteredMonth === 0) {
            enteredMonth = '01';
        }

        enteredMonth = enteredMonth.length === 1 && !enteredMonth.match(/^0/) ?
                            enteredMonth.padStart(2,'0') :
                            enteredMonth.length === 3 ? enteredMonth.replace(/^0/, '') : enteredMonth;

        date = (dateParts[0] ? dateParts[0] : '') + '-' + enteredMonth + '-' + (dateParts[2] ? dateParts[2] : '');
        date = date !== "--" ? date : '';
        
        executeValidators(date, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid);
        props.setInputValue(date);
    }

    const validateYear = (event:any) => {
        const maxYear = 9999;
        if(event.target.value > maxYear) {
            return;
        }

        let date = props.inputValue;
        let dateParts = props.inputValue.split('-');
        let enteredYear = event.target.value > maxYear ? new Date().getFullYear() : event.target.value;

        enteredYear = enteredYear.length > 0 && enteredYear.length < 4 && !enteredYear.match(/^0/) ? 
                        enteredYear.padStart(4,'0') :
                        enteredYear.length === 5 ? enteredYear.replace(/^0/, '') : enteredYear;

        date = enteredYear + '-' + (dateParts[1] ? dateParts[1] : '') + '-' + (dateParts[2] ? dateParts[2] : '');
        date = date !== "--" ? date : '';
        
        executeValidators(date, props.validators, setHint, props.setInputHint!, props.label, props.setInputValid);
        props.setInputValue(date);
    }

    return (
        <>
            {!props.hideLabel && <Label 
                id={props.id} 
                label={props.label} 
                mandatory={props.mandatory} 
                persistentHint={props.persistentHint && props.hintPosition==='above' ? props.persistentHint : 
                                props.flexibleDate ? "Date must be entered if displayed on your document eg. dd/mm/yyyy or mm/yyyy or yyyy" : null} 
                className={props.labelClassName}
            />}
            {!props.isInputValid? <em className="error-label">{(props.inputHint) ? props.inputHint : hint}</em> : null }
            <div className="container">
                <div className="row">
                    <div className="col-2 qdi-date-input-wrapper">
                        <div className="hint">Day</div>
                        <div>
                            <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                                name={props.id+'Day'}
                                id={props.id+'Day'}
                                type="number"
                                value={props.inputValue.length > 0 ? props.inputValue.split('-')[2] : ''}
                                placeholder="dd"
                                title= 'Day'
                                pattern="[0-9]*"
                                max={111} //Setting this to 3 digit value to get more space in the textbox in mobile devices. Max allowed will be 31.
                                min={1}
                                onBlur={validateDay}
                                onChange={validateDay}
                            />
                        </div>
                    </div>
                    <div className="col-1 qdi-date-seperator">
                        <div>&nbsp;</div>
                        <div>/</div>
                    </div>
                    <div className="col-2 qdi-date-input-wrapper">
                        <div className="hint">Month</div>
                        <div>
                            <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                                name={props.id+'Month'}
                                id={props.id+'Month'}
                                type="number"
                                value={props.inputValue.length > 0 ? props.inputValue.split('-')[1] : ''}
                                placeholder="mm"
                                title= 'Month'
                                pattern="[0-9]*"
                                max={111} //Setting this to 3 digit value to get more space in the textbox in mobile devices. Max allowed will be 12.
                                min={1}
                                onBlur={validateMonth}
                                onChange={validateMonth}
                                ref={monthRef}
                            />
                        </div>
                    </div>
                    <div className="col-1 qdi-date-seperator">                        
                        <div>&nbsp;</div>
                        <div>/&nbsp;</div>
                    </div>
                    <div className="col-3 qdi-date-input-wrapper">
                        <div className="hint">Year</div>
                        <div>
                            <input className={"form-control" + (props.isInputValid ? "" : " error input-alert")}
                                name={props.id+'Year'}
                                id={props.id+'Year'}
                                type="number"
                                value={props.inputValue.length > 0 ? props.inputValue.split('-')[0] : ''}
                                placeholder="yyyy"
                                title= 'Year'
                                pattern="[0-9]*"
                                max={11111} //Setting this to 3 digit value to get more space in the textbox in mobile devices. Max allowed will be 9999.
                                min={1}
                                onBlur={validateYear}
                                onChange={validateYear}
                                ref={yearRef}
                            />
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
            {(props.persistentHint && props.hintPosition==='below')? <small className="hint">{ props.persistentHint }</small> : null }
        </>
    );
}

LabelDateInput.defaultProps = {
    hintPosition: "above",
    mode: "number",
    flexibleDate: false
}

export default LabelDateInput;