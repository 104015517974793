import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ImmiCard from "../../components/qdi/form/immicard-form";
import { createElement } from "react";
import Product from "../../components/qdi/product";
import { verifyImmiCard } from "../../services/x-api";

export const ImmiCardForm = () => {
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const imageUrl = require('../../assets/images/Immigration Status or Australian Migration Status.png');

    return (
        <>
            {isAuthenticated && 
                <div>
                    <h1>Verify your second credential</h1>

                    <ImmiCard 
                        id="ImmiCard"
                        instructions={createElement('p', {}, 'Enter your information exactly as it appears on your ImmiCard.')}
                        product={createElement(Product, 
                            { heading:"ImmiCard - Evidence of Immigration Status or Australian Migration Status",
                              media:imageUrl,
                              selected:true,
                              disabled:true})}
                        backNavigateTo="/ip-uplift/select-other-document"
                        nextNavigateTo="/ip-uplift/select-other-document"
                        typeCode="IM"
                        identifierType="ImmiCard Number"
                        action={verifyImmiCard}
                        allowSingleName={true}
                    />
                </div>
            }
        </>
    );
};

export default ImmiCardForm;