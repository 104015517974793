import {Call, VERBS} from "./api-bridge";
import { DocumentAlreadyVerifiedError, DocumentVerificationError } from "./x-api";
import * as Constants from "../constants";

/**
 * Send a verification code to the user's email.
 * 
 * @param token the authorization token from the IDP.
 * @param email the email address to send the verification code to.
 * @param checkEmail if this is true, API will look existing Auth0 accounts with this email 
 * and return 409 response if there is a maching account.
 */
export async function sendVerificationCode(token:string, email:string, checkEmail:string = "false") {
    let body = {
        "InitQDIUserVerification": {
            "email": email,
            "verification_type": "email",
            "check_email": checkEmail
          }
    }
    const returned = await Call('qdi/users/identity/verification/init', VERBS.POST, body, token);
    if (200 !== returned.code && 409 !== returned.code) {
        throw new Error("Invalid response.");
    } 

    return returned;
}

/**
 * Verify a verification code sent to the user's email.
 * 
 * @param token the authorization token from the IDP.
 * @param email the email address the verification was sent to.
 * @param code  the code in the verification mail.
 * @returns 
 */
export async function verifyVerificationCode(token:string, email:string, code:string, userId:string) {
    let body= {
        "VerifyQDIUserOTP": {
            "user_id": userId,
            "email": email,
            "verification_type": "email",
            "otp": code
        }
    }
    const returned = await Call('qdi/users/identity/verification/otp', VERBS.POST, body, token);
    if (409 == returned.code) {
        throw new DocumentAlreadyVerifiedError();
    } 
    else if (403 == returned.code) {
        // 403 response from API does not return an indicator. Passing S (System Error) as the failureCode.
        throw new DocumentVerificationError('S', returned.body.Results[0]?.ErrorResponse.Description);
    }
    else if (200 !== returned.code) {
        throw new Error("Unhandled response.");
    }
    
    return returned;
}

export async function generateMFAToken(token:string, state:string, subject:string, sessionId:string) {
    let body = {
        "GenerateReturnSessionToken": {
            "state": state,
            "sub": subject,
            "iss": Constants.TMP_DLA_URI,
            "ip_uplift_session_id": sessionId
          }
    }
    const returned = await Call('qdi/users/identity/verification/returnsessiontoken', VERBS.POST, body, token, [200]);
    return returned;
}

export {}