import { useAuth0 } from '@auth0/auth0-react';
import * as Constants from "../../constants";
import RelyingPartyBuilder from '../../utils/RelyingPartyBuilder';
import { useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/swe/primary-button";
import CriticalAlert from '../../components/swe/critical-alert';
import useGlobalState from "../../hooks/useGlobalState";

export const NoEligibleCustomer = () => {
    const { isAuthenticated, user } = useAuth0();
    const { globalState } = useGlobalState();
    const navigate = useNavigate();
    const alertRef = useRef(null);

    if (Constants.DEBUG) { console.log("%cDocument is ineligible: user: %o", 'color: #f96;', user); }

    useEffect(() => {
        (async () => {
            if (!isAuthenticated) {
                window.location.href = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
            } else {
                let mfaAvailable = globalState.userCompletedMFA;
                if (typeof mfaAvailable === 'undefined' || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "page blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
        })();
    })

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    function returnToDLA() {
        if (Constants.DEBUG) { console.log("Returning to RP"); }
        window.location.href = new RelyingPartyBuilder()
            .withRelyingParty(Constants.TMP_DLA_URI)
            .withReturnCode(6)
            .withAcValue("IP1:CL2")
            .build();
    }

    return (<>
        {isAuthenticated &&
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Identity</h1>

                <div tabIndex={-1} ref={alertRef}>
                    <CriticalAlert heading="Document is ineligible."
                        message={<p>The document you are attempting to verify is ineligible. <br />If you believe this to be incorrect, <a href={Constants.HELP_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>} />
                </div>

                <ol className="questions">
                    <li className="footer">
                        <ul className="actions">
                            <PrimaryButton id="continue" heading="Continue" action={returnToDLA} />
                        </ul>
                    </li>
                </ol>

            </div>
        }
    </>)
};

export default NoEligibleCustomer;
