import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

import history from "./utils/history";
import useGlobalState from "./hooks/useGlobalState";
import ErrorBoundary from "./components/error/ErrorBoundary";

import "./App.css";
import initFontAwesome from "./utils/initFontAwesome";

import Loading from "./components/Loading";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Aside from "./components/Aside";

import Login from "./views/Login";
import Logout from "./views/Logout";
import Callback from "./views/Callback";
import Home from "./views/Home";
import TermsAndConditions from "./views/TermsAndConditions";

import TryAgain from "./views/TryAgain";
import TooManyAttempts from "./views/TooManyAttempts";
import TooManyResendAttempts from "./views/TooManyResendAttempts";
import ServiceUnavailable from "./views/ServiceUnavailable";
import NotFound from "./views/NotFound";
import ChangeEmail from "./views/ChangeEmail";
import EmailAlreadyUsed from "./views/EmailAlreadyUsed";
import EmailOTP from "./views/EnterEmailOneTimeCode";
import SessionTimeout from "./views/SessionTimeout";

import IpUplift from "./views/IpUplift";
import VerifyYourIdentity from "./views/VerifyYourIdentity";
import DriversLicenceForm from "./views/verify-your-identity/DriversLicenceForm";
import PhotoIdForm from "./views/verify-your-identity/PhotoIdForm";
import NoEligibleCustomer from "./views/verify-your-identity/NoEligibleCustomer";
import MarineLicenceForm from "./views/verify-your-identity/MarineLicenceForm";
import SelectOtherDocument from "./views/SelectOtherDocument";
import PassportForm from "./views/select-other-document/PassportForm";
import BirthCertificateForm from "./views/select-other-document/BirthCertificateForm";
import VisaForm from "./views/select-other-document/VisaForm";
import MarriageCertificate from "./views/name-change-supporting-document/MarriageCertificate";
import NamesDontMatch from "./views/name-change-supporting-document/NamesDontMatch";
import CitizenshipCertificateForm from "./views/select-other-document/CitizenshipCertificateForm";
import ImmiCardForm from "./views/select-other-document/ImmCardForm";
import NameChangeOptions from "./views/NameChangeOptions";
import ChangeOfNameCertificate from "./views/name-change-supporting-document/ChangeOfNameCertificate";
import InvalidRelyingParty from "./views/InvalidRelyingParty";
import * as Constants from "./constants";
import Success from "./views/Success";
import RelyingPartyAndServiceStatusCheck from "./components/RelyingPartyAndServiceStatusCheck";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();
  const { globalState } = useGlobalState();

  /**
  * Get the referrer and check against list of approved referrers
  * currently only DLA
  */
  const referringUrl = sessionStorage.getItem('QDI-RP') ? sessionStorage.getItem('QDI-RP') : document.referrer;
  let allowedRelyingPartysArray = Constants.ALLOWED_RELYING_PARTYS ? Constants.ALLOWED_RELYING_PARTYS.split(',') : [];

  useEffect(() => {
    if (Constants.DEBUG) { console.log('The referring url is', referringUrl); }

    if (referringUrl === null || !allowedRelyingPartysArray.includes(referringUrl)) {
      sessionStorage.setItem('QDI-RP', Constants.INVALID_RELYING_PARTY_REDIRECT);
    } else {
      sessionStorage.setItem('QDI-RP', referringUrl);
    }

    if (Constants.DEBUG) { console.log('QDI-RP is ', sessionStorage.getItem('QDI-RP')); }
    if (Constants.DEBUG) { console.log('TMP_DLA is ', Constants.TMP_DLA_URI); }

  }, [])
  //////////////////////////////////////////////////

  if (error) {
    console.error(error);
  }

  if (isLoading) {
    return <Loading maxheight={true} />;
  }

  return (
    <>
      <Router history={history}>
        <Header />
        <RelyingPartyAndServiceStatusCheck />

        <div className="container-fluid qg-site-width">
          <div id="qg-content">
            <div id="qg-three-col" className="row">
              <div id="qg-primary-content" role="main">
                {(error && <div>Oops... {error.message}</div>)
                  ||
                  (<ErrorBoundary globalState={globalState}>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/signup" element={<Home />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                      <Route path="/callback" element={<Callback />} />

                      <Route path="/callback-ip1" element={<IpUplift />} />

                      <Route path="/change-email" element={<ChangeEmail />} />
                      <Route path="/email-error" element={<EmailAlreadyUsed />} />
                      <Route path="/service-unavailable" element={<ServiceUnavailable />} />

                      <Route path="/ip-uplift" element={<IpUplift />} />
                      <Route path="/ip-uplift/enter-otp" element={<EmailOTP />} />
                      <Route path="/ip-uplift/email-changed" element={<EmailOTP />} />

                      <Route path="/ip-uplift/verify-your-identity" element={<VerifyYourIdentity />} />
                      <Route path="/ip-uplift/verify-your-identity/drivers-licence-form" element={<DriversLicenceForm />} />
                      <Route path="/ip-uplift/verify-your-identity/photo-id-form" element={<PhotoIdForm />} />
                      <Route path="/ip-uplift/verify-your-identity/marine-licence-form" element={<MarineLicenceForm />} />

                      <Route path="/ip-uplift/verify-your-identity/no-eligible-customer" element={<NoEligibleCustomer />} />

                      <Route path="/ip-uplift/select-other-document" element={<SelectOtherDocument />} />
                      <Route path="/ip-uplift/select-other-document/passport-form" element={<PassportForm />} />
                      <Route path="/ip-uplift/select-other-document/birth-certificate-form" element={<BirthCertificateForm />} />
                      <Route path="/ip-uplift/select-other-document/visa-form" element={<VisaForm />} />
                      <Route path="/ip-uplift/select-other-document/citizenship-certificate-form" element={<CitizenshipCertificateForm />} />
                      <Route path="/ip-uplift/select-other-document/immicard-form" element={<ImmiCardForm />} />

                      <Route path="/ip-uplift/name-change-options" element={<NameChangeOptions />} />
                      <Route path="/ip-uplift/name-change-options/marriage-certificate-form" element={<MarriageCertificate />} />
                      <Route path="/ip-uplift/name-change-options/change-of-name-certificate-form" element={<ChangeOfNameCertificate />} />
                      <Route path="/ip-uplift/names-dont-match" element={<NamesDontMatch />} />
                      <Route path="/ip-uplift/too-many-attempts" element={<TooManyAttempts />} />
                      <Route path="/ip-uplift/too-many-resend-attempts" element={<TooManyResendAttempts />} />

                      <Route path="/ip-uplift/success" element={<Success/>} />

                      <Route path="/try-again" element={<TryAgain />} />
                      <Route path="/session-timeout" element={<SessionTimeout />} />
                      <Route path="/invalidRelyingParty" element={<InvalidRelyingParty />} />

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </ErrorBoundary>)
                }
              </div>

              <Aside />

            </div>
          </div>
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;